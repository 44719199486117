import { adminActions } from '@/store/modules/admin/names';
import { generalMutations } from '@/store/modules/general/names';
import { Store } from 'vuex';

export const initApp = async (store: Store<any>) => {
    const refresh = await store.dispatch(adminActions.refresh);

    if (refresh) {
        await store.dispatch(adminActions.regularRefresh);
    }

    store.commit(generalMutations.TOGGLE_INIT_STATUS, false);
};