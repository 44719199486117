import Vue from 'vue';

import Vuelidate from 'vuelidate';

// Styles
import '@/assets/css/helpers.scss';
import '@/assets/css/styles.scss';
import 'vue-tel-input/dist/vue-tel-input.css';
import '@/assets/css/flags.css';

// Components
import '@/tools/requireComponent';

// Plugins
import '@/plugins';
import vuetify from '@/plugins/vuetify/index';
import '@/api/axios-instance';
import apolloProvider from '@/plugins/apollo/index';
import moment from 'moment';
import VueTelInput from 'vue-tel-input';

// Sync router with store
import { sync } from 'vuex-router-sync';

// Application imports
import App from './App.vue';
import router from '@/router';
import store from '@/store';
import '@/registerServiceWorker';

// Layouts
import Main from '@/layouts/Main.vue';
import Login from '@/layouts/Login.vue';
import Initialization from '@/layouts/Initialization.vue';

import VueMask from 'v-mask';

import './plugins/sentry';

Vue.use(VueMask);

Vue.use(VueTelInput);

Vue.component('MainLayout', Main);
Vue.component('LoginLayout', Login);
Vue.component('Initialization', Initialization);

Vue.filter('formatDate', (value) => {
    if (!value) {
        return;
    }
    const newDate = new Date(value);
    return moment(newDate).format('YYYY-MM-DD');
});

// Sync store with router
sync(store, router);

Vue.use(Vuelidate);

Vue.config.productionTip = false;

const app = new Vue({
    vuetify,
    router,
    store,
    apolloProvider,
    render: (h) => h(App),
}).$mount('#app');

export default app;