import { actions } from './actions';
import { mutations } from './mutations';
import { getters } from './getters';
import { UsersState } from './types';

const state: UsersState = {
    loadedStatus: false,
    user: {
        birthDate: '',
        country: '',
        email: '',
        id: 0,
        is_active: true,
        is_delete: false,
        is_female: false,
        name: '',
        orders: [],
        password: '',
        paymentData: {
            bankName: '',
            accountNumber: '',
            holderName: '',
        },
        phone: '',
        surname: '',
        paid_till: '',
    },
    defaultCountry: '',
    donations: [],
    payments: null,
    specialUsers: [],
    count: 0,
    specialUsersTotal: 0,
    users: [],
};

export default {
    namespaced: true,
    state: () => state,
    actions,
    mutations,
    getters,
};