import { ActionTree } from 'vuex';

import graphqlQueries from '@/api/index.graphql';
import adminApi from '@/store/modules/admin/services';

import { AdminState } from './types';
import { ComposedState, Login } from '@/types/index';

import { actionsNames, mutationsNames } from './names';

// eslint-disable-next-line
let updateInterval: null | ReturnType<typeof setTimeout>;

export const actions: ActionTree<AdminState, ComposedState> = {
    async [actionsNames.fetchAdmin]({ commit }) {
        const res = await graphqlQueries.fetchAdmin();

        const admin = res.data.me;
        commit(mutationsNames.UPDATE_ADMIN, admin);
    },

    async [actionsNames. getToken]({ commit, dispatch }, login: Login) {
        const res = await adminApi.auth(login);

        if (res && res.status === 200) {
            commit(mutationsNames.SET_TOKEN, res.data.access);

            await adminApi.setHeaders({
                header: 'Authorization',
                value: `JWT ${res.data.access}`,
            });

            await dispatch(actionsNames.regularRefresh);

            return true;
        }
        return false;
    },

    async [actionsNames.refresh]({ commit }) {
        const res = await adminApi.refresh();

        if (res && res.status === 200 && res.data.access) {
            commit(mutationsNames.SET_TOKEN, res.data.access);

            await adminApi.setHeaders({
                header: 'Authorization',
                value: `JWT ${res.data.access}`,
            });

            return true;
        }
        return false;
    },

    async [actionsNames.regularRefresh]({ dispatch }) {
        const interval = 1000 * 4.9 * 60;
        
        updateInterval = setInterval(() => {
            dispatch(actionsNames.refresh);
        }, interval);
    },

    async [actionsNames.logout]({ commit }) {
        const res = await adminApi.logout();

        if (res && res.status === 200) {
            await adminApi.setHeaders({
                header: 'Authorization',
                value: null,
            });

            commit(mutationsNames.SET_TOKEN, null);
            commit(mutationsNames.UPDATE_ADMIN, null);

            return true;
        }

        return false;
    },
};