import { MutationTree } from 'vuex';
import { mutationsNames } from './names';
import { OperatorsState, OperatorCreate, OperatorItem } from './types';

export const mutations: MutationTree<OperatorsState> = {
    [mutationsNames.UPDATE_SET_OPERATOR](state, loaderSetOperator: boolean) {
        state.loaderSetOperator = loaderSetOperator;
    },

    [mutationsNames.UPDATE_MODAL_STATE_CONTROL](state, modalStateControl: boolean) {
        state.modalStateControl = modalStateControl;
    },

    [mutationsNames.UPDATE_CREATE_EMAIL_ERROR](state, createEmailError: string) {
        state.createEmailError = createEmailError;
    },

    [mutationsNames.UPDATE_OPERATOR](state, operator: OperatorCreate) {
        state.operator = operator;
    },

    [mutationsNames.UPDATE_PASSWORD_SERVER_ERROR](state, passwordServerError: string) {
        state.passwordServerError = passwordServerError;
    },

    [mutationsNames.UPDATE_OPERATOR_HANDLE](state, operatorHandle: boolean) {
        state.operatorHandle = operatorHandle;
    },

    [mutationsNames.UPDATE_CHECK_OPERATOR_RESET](state, checkOperatorReset: boolean) {
        state.checkOperatorReset = checkOperatorReset;
    },
    
    [mutationsNames.UPDATE_OPERATORS](state, operators: OperatorItem[]) {
        state.operators = operators;
    },
    [mutationsNames.UPDATE_PRELOADER](state, preloader: boolean) {
        state.preloader = preloader;
    },
};