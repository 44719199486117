import { MutationTree } from 'vuex';
import { mutationsNames } from './names';

import {
    DreamsState,
    IDreamItem,
    IExtendedDreamItem,
    FetchStatuses,
} from './types';
import { IRelations } from '@/types';

export const mutations: MutationTree<DreamsState> = {
    [mutationsNames.SET_DREAMS](state, payload: IDreamItem[]) {
        state.dreams = payload;
    },

    [mutationsNames.SET_DREAM_ITEM](state, payload: IDreamItem) {
        state.dreamItem = payload;
    },

    [mutationsNames.SET_DREAM_CHANGE_ITEM](state, payload: IExtendedDreamItem) {
        state.dreamChangeItem = {
            description: payload.description,
            picture: payload.picture,
            type_dream: payload.type_dream,
            title: payload.title,
            user_id: payload.user.id,
            goal: payload.goal,
            category_id: payload.category.id, 
        };
    },
    [mutationsNames.UPDATE_DREAM_CHANGE_ITEM](
        state,
        payload: { title: string; value: any },
    ) {
        state.dreamChangeItem = {
            ...state.dreamChangeItem,
            [payload.title]: payload.value,
        };
    },
    [mutationsNames.SET_TOTAL_DREAMS](state, payload) {
        state.dreamsTotal = payload;
    },
    [mutationsNames.SET_USER_CURRENCY](state, payload) {
        state.createDreamUserCurrency = payload;
    },
    [mutationsNames.SET_CREATE_DREAM_STATUS](state, status: FetchStatuses) {
        state.createDreamApiStatus = status;
    },
    [mutationsNames.SET_RELATIONS](state, payload: IRelations[]) {
        state.relations = payload;
    },
};