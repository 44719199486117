import { actions } from './actions';
import { mutations } from './mutations';
import { DreamsState, FetchStatuses } from './types';

const state: DreamsState = {
    dreams: [],
    categoryList: [
        {
            id: 1,
            title_cat: 'Путешествия',
        },
        {
            id: 2,
            title_cat: 'Мечты/Желания',
        },
        {
            id: 3,
            title_cat: 'Образование',
        },
        {
            id: 4,
            title_cat: 'Здоровье',
        },
        {
            id: 5,
            title_cat: 'Бизнес/Стартапы',
        },
        {
            id: 6,
            title_cat: 'Спорт',
        },
        {
            id: 7,
            title_cat: 'Домашние животные',
        },
        {
            id: 8,
            title_cat: 'Экология',
        },
        {
            id: 9,
            title_cat: 'Мероприятия',
        },
        {
            id: 10,
            title_cat: 'Креативные идеи',
        },
        {
            id: 11,
            title_cat: 'Чрезвычайные ситуации',
        },
        {
            id: 12,
            title_cat: 'Пожертвования',
        },        
        {
            id: 13,
            title_cat: 'Благотворительность и волонтерство',
        },
    ],
    dreamItem: null,
    dreamChangeItem: null,
    dreamsTotal: 0,
    createDreamUserCurrency: null,
    createDreamApiStatus: FetchStatuses.default,
    relations: null,
    hide_landing: false,
};

export default {
    namespaced: true,
    state: () => state,
    actions,
    mutations,
};