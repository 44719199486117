
import Vue from 'vue';

import { mapActions, mapMutations, mapState } from 'vuex';

import {
    required,
    minLength,
} from 'vuelidate/lib/validators';

import { operatorsActions, operatorsMutations } from '@/store/modules/operators/names';

import { ComposedState } from '@/types';

export default Vue.extend({
    name: 'OperatorItem',

    validations: {
        name: { required },
        superUserModel: { required, minLength: minLength(8) },
        contentManagerModel: { required, minLength: minLength(8) },
    },

    data() {
        return {
            blockOperatorModal: false,
            blockOperatorPassword: '',

            handleSuperUserModal: false,
            superUserModel: '',

            handleContentManagerModal: false,
            contentManagerModel: '',

            changeEmailModel: '',
            editEmailDialog: false,
            disabledBtnSaveNewEmail: false,

            changeNameModel: '',
            editNameDialog: false,
            disabledBtnSaveNewName: true,

            processCheckPassword: false,

            loaderSetUpdateOperator: {
                state: false,
                preloader: true,
                icon: false,
            },

            updateNameRules: [
                (v: string) => {
                    if (v) {
                        // @ts-ignore
                        this.disabledBtnSaveNewName = false;
                        return true;
                    }

                    // @ts-ignore
                    this.disabledBtnSaveNewName = true;
                    return 'Name must be valid';
                    
                },
            ],

            emailRules: [
                (v: string) => {
                    if (v) {
                        // @ts-ignore
                        this.disabledBtnSaveNewEmail = false;
                        return true;
                    } 

                    // @ts-ignore
                    this.disabledBtnSaveNewEmail = true;
                    return 'E-mail is required';
                    
                },
                (v: string) => {
                    if (/.+@.+\..+/.test(v)) {
                        // @ts-ignore
                        this.disabledBtnSaveNewEmail = false;
                        return true;
                    }

                    // @ts-ignore
                    this.disabledBtnSaveNewEmail = true;
                    return 'E-mail must be valid';
                },
            ],

            checkResetPassword: false,
        };
    },

    computed: {
        ...mapState<ComposedState>({
            operator: (state: ComposedState) => state.operators.operator,
            passwordServerError: (state: ComposedState) => state.operators.passwordServerError,
            operatorHandle: (state: ComposedState) => state.operators.operatorHandle,
            checkOperatorReset: (state: ComposedState) => state.operators.checkOperatorReset,
        }),

        alertPassword() {
            // @ts-ignore
            return !!this.passwordServerError;
        },

        passwordErrors() {
            const errors: string[] = [];
            if (!this.$v.superUserModel.$dirty) return errors;
            if (!this.$v.superUserModel.minLength) {
                errors.push('Min 8 characters.');
            }
            if (!this.$v.superUserModel.required) {
                errors.push('Password is required.');
            }

            return errors;
        },

        operatorId() {
            return +this.$route.params.id;
        },
    },

    async mounted() {
        await this.fetchOperator(this.operatorId);
    },

    methods: {
        ...mapMutations({
            updatePasswordServerError: operatorsMutations.UPDATE_PASSWORD_SERVER_ERROR,
            updateCheckOperatorReset: operatorsMutations.UPDATE_CHECK_OPERATOR_RESET,
        }),
        ...mapActions({
            fetchOperator: operatorsActions.fetchOperator,
            mutateOperator: operatorsActions.mutateOperator,
            promoteOperator: operatorsActions.promoteOperator,
            operatorResetPassword: operatorsActions.operatorResetPassword,
            manageContentManagerRole: operatorsActions.manageContentManagerRole,
            blockOperator: operatorsActions.blockOperator,
            unblockOperator: operatorsActions.unBlockOperator,
        }),

        async changeName() {
            this.loaderSetUpdateOperator.state = true;
            this.loaderSetUpdateOperator.preloader = true;
            this.loaderSetUpdateOperator.icon = false;

            await this.mutateOperator({
                id: this.operatorId,
                name: this.changeNameModel,
            });

            this.loaderSetUpdateOperator.preloader = false;
            this.loaderSetUpdateOperator.icon = true;

            setTimeout(() => {
                this.editNameDialog = false;
                this.loaderSetUpdateOperator.state = false;
            }, 1000);
        },

        async changeEmail() {
            this.loaderSetUpdateOperator.state = true;
            this.loaderSetUpdateOperator.preloader = true;
            this.loaderSetUpdateOperator.icon = false;

            await this.mutateOperator({
                id: this.operatorId,
                email: this.changeEmailModel,
            });

            this.loaderSetUpdateOperator.preloader = false;
            this.loaderSetUpdateOperator.icon = true;

            setTimeout(() => {
                this.editEmailDialog = false;
                this.loaderSetUpdateOperator.state = false;
            }, 1000);
        },

        async switchBlockOperator() {
            // @ts-ignore
            if (this.operator.isActive) {
                await this.blockOperator({
                    id: this.operatorId,
                    password: this.blockOperatorPassword,
                });
            } else {
                await this.unblockOperator({
                    id: this.operatorId,
                    password: this.blockOperatorPassword,
                });
            }

            this.fetchOperator(this.operatorId);
            
            setTimeout(() => {
                this.closeBlockOperatorModal();
            }, 1000);
        },

        async handlerSuperUser() {
            this.$v.superUserModel.$touch();

            if (!this.$v.superUserModel.$invalid) {
                this.processCheckPassword = true;

                await this.promoteOperator({
                    id: this.operatorId,
                    password: this.superUserModel,
                });

                // @ts-ignore
                if (this.operatorHandle) {
                    setTimeout(() => {
                        this.handleSuperUserModal = false;
                    }, 800);
                }

                this.processCheckPassword = false;
            }
        },

        async handlerContentManger() {
            this.$v.contentManagerModel.$touch();

            if (!this.$v.contentManagerModel.$invalid) {
                this.processCheckPassword = true;

                await this.manageContentManagerRole({
                    id: this.operatorId,
                    password: this.contentManagerModel,
                });

                // @ts-ignore
                if (this.operatorHandle) {
                    setTimeout(() => {
                        this.handleContentManagerModal = false;
                    }, 800);
                }

                this.processCheckPassword = false;
            }
        },

        closeBlockOperatorModal() {
            this.blockOperatorModal = false;
            (this.$refs.checkOperatorName as HTMLFormElement).reset();
        },

        closeSuperuserModal() {
            this.updatePasswordServerError('');

            this.handleSuperUserModal = false;
            this.superUserModel = '';
        },

        closeContentManagerModal() {
            this.updatePasswordServerError('');

            this.handleContentManagerModal = false;
            this.contentManagerModel = '';
        },

        async resetPassword() {
            this.checkResetPassword = true;
            await this.operatorResetPassword(this.operatorId);

            this.checkResetPassword = false;

            setTimeout(() => {
                this.updateCheckOperatorReset(false);
            }, 2000);
        },
    },
});
