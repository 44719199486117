import graphqlQueries from '@/api/index.graphql';

import rest from '@/api/index';

export default {
    async getDashboard() {
        return graphqlQueries.getDashboard();
    },
    
    async exportDashboard(dateFrom: string, dateTo: string) {
        return rest.exportDashboard(dateFrom, dateTo);
    },
};