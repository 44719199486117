import { Payments } from '@/types';
import { MutationTree } from 'vuex';
import { mutationsNames } from './names';

import {
    UsersState,
    UserCreate,
    Donate,
    ISpecialUsers,
    User,
} from './types';

export const mutations: MutationTree<UsersState> = {
    [mutationsNames.UPDATE_USER](state, userCreate: UserCreate) {
        state.user = userCreate;
    },

    [mutationsNames.UPDATE_USER_EMAIL](state, email: string) {
        state.user = { ...state.user, email };
    },

    [mutationsNames.UPDATE_PAYMENTS](state, payments: Payments) {
        state.payments = payments;
    },

    [mutationsNames.UPDATE_LOADED_STATUS](state, status: boolean) {
        state.loadedStatus = status;
    },

    /* eslint-disable-next-line */
    [mutationsNames.SET_VIP_STATUS](state, is_vip: boolean | null) {
        state.user = { ...state.user, is_vip };
    },

    [mutationsNames.SET_DONATIONS](state, payload: Donate[]) {
        state.donations = payload;
    },

    [mutationsNames.UPDATE_DEFAULT_COUNTRY](state, payload: string) {
        state.defaultCountry = payload;
    },

    [mutationsNames.UPDATE_USER_STATUS](state, payload: boolean) {
        state.user = { ...state.user, is_active: payload };
    },

    [mutationsNames.DELETE_USER](state, payload: boolean) {
        state.user = { ...state.user, is_delete: payload };
    },

    [mutationsNames.UPDATE_USERS_COUNT](state, count: number) {
        state.count = count;
    },

    [mutationsNames.UPDATE_SPECIAL_USERS](state, users: ISpecialUsers[]) {
        state.specialUsers = users;
    },

    [mutationsNames.SET_SPECIAL_USERS_TOTAL](state, payload: number) {
        state.specialUsersTotal = payload;
    },

    [mutationsNames.UPDATE_USERS](state, payload: User[]) {
        state.users = payload;
    },
};