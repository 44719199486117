
import Vue from 'vue';

import { mapActions } from 'vuex';

import { adminActions } from '@/store/modules/admin/names';

export default Vue.extend({
    async mounted() {
        await this.getAdmin();
    },

    methods: {
        ...mapActions({
            getAdmin: adminActions.fetchAdmin,
        }),
    },
});
