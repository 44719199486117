import { addModuleName } from '@/tools/addModuleNames';

const MODULE_NAME = 'dreams';

/* eslint-disable */
enum mutationsNames {
    SET_DREAMS = 'SET_DREAMS',
    SET_DREAM_ITEM = 'SET_DREAM_ITEM',
    SET_DREAM_CHANGE_ITEM = 'SET_DREAM_CHANGE_ITEM',
    UPDATE_DREAM_CHANGE_ITEM = 'UPDATE_DREAM_CHANGE_ITEM',
    SET_TOTAL_DREAMS = 'SET_TOTAL_DREAMS',
    SET_USER_CURRENCY = 'SET_USER_CURRENCY',
    SET_CREATE_DREAM_STATUS = 'SET_CREATE_DREAM_STATUS',
    SET_RELATIONS = 'SET_RELATIONS',
}

enum actionsNames {
    getDreams = 'getDreams',
    getDreamItem = 'getDreamItem',
    changeDreamItem = 'changeDreamItem',
    createDreamItem = 'createDreamItem',
    loadUserCurrency = 'loadUserCurrency',
    getRelations = 'getRelations',
}
/* eslint-enable */

const dreamsMutations = {
    [mutationsNames.SET_DREAMS]: addModuleName(
        MODULE_NAME,
        mutationsNames.SET_DREAMS,
    ),
    [mutationsNames.SET_DREAM_ITEM]: addModuleName(
        MODULE_NAME,
        mutationsNames.SET_DREAM_ITEM,
    ),
    [mutationsNames.SET_DREAM_CHANGE_ITEM]: addModuleName(
        MODULE_NAME,
        mutationsNames.SET_DREAM_CHANGE_ITEM,
    ),
    [mutationsNames.UPDATE_DREAM_CHANGE_ITEM]: addModuleName(
        MODULE_NAME,
        mutationsNames.UPDATE_DREAM_CHANGE_ITEM,
    ),
    [mutationsNames.SET_TOTAL_DREAMS]: addModuleName(
        MODULE_NAME,
        mutationsNames.SET_TOTAL_DREAMS,
    ),
    [mutationsNames.SET_USER_CURRENCY]: addModuleName(
        MODULE_NAME,
        mutationsNames.SET_USER_CURRENCY,
    ),
    [mutationsNames.SET_CREATE_DREAM_STATUS]: addModuleName(
        MODULE_NAME,
        mutationsNames.SET_CREATE_DREAM_STATUS,
    ),
    [mutationsNames.SET_RELATIONS]: addModuleName(
        MODULE_NAME,
        mutationsNames.SET_RELATIONS,
    ),
};

const dreamsActions = {
    [actionsNames.getDreams]: addModuleName(
        MODULE_NAME,
        actionsNames.getDreams,
    ),
    [actionsNames.getDreamItem]: addModuleName(
        MODULE_NAME,
        actionsNames.getDreamItem,
    ),
    [actionsNames.changeDreamItem]: addModuleName(
        MODULE_NAME,
        actionsNames.changeDreamItem,
    ),
    [actionsNames.createDreamItem]: addModuleName(
        MODULE_NAME,
        actionsNames.createDreamItem,
    ),
    [actionsNames.loadUserCurrency]: addModuleName(
        MODULE_NAME,
        actionsNames.loadUserCurrency,
    ),
    [actionsNames.getRelations]: addModuleName(
        MODULE_NAME,
        actionsNames.getRelations,
    ),
};

export {
    mutationsNames,
    dreamsMutations,
    actionsNames,
    dreamsActions,
};