import { ActionTree } from 'vuex';

import { OperatorsState, OperatorCreate, OperatorPromote, OperatorUpdate } from './types';

import { ComposedState } from '@/types/index';

import graphqlQueries from '@/api/index.graphql';

import { actionsNames, mutationsNames } from './names';

export const actions: ActionTree<OperatorsState, ComposedState> = {
    [actionsNames.createOperator]: async ({ state, commit }, operatorData: OperatorCreate) => {
        commit(mutationsNames.UPDATE_SET_OPERATOR, true);

        try {
            const res = await graphqlQueries.operatorCreate(operatorData);

            const addOperator = res.data?.operatorCreate.operator;

            state.operators.unshift(addOperator as OperatorCreate); 

            const SetOperator = !(addOperator);
            commit(mutationsNames.UPDATE_SET_OPERATOR, SetOperator);

            commit(mutationsNames.UPDATE_OPERATOR, addOperator);

            commit(mutationsNames.UPDATE_MODAL_STATE_CONTROL, false);
            commit(mutationsNames.UPDATE_CREATE_EMAIL_ERROR, '');
        } catch (error) {
            commit(mutationsNames.UPDATE_CREATE_EMAIL_ERROR, error);

            commit(mutationsNames.UPDATE_SET_OPERATOR, false);
            commit(mutationsNames.UPDATE_MODAL_STATE_CONTROL, true);
        }
    },

    [actionsNames.operatorResetPassword]: async ({ commit }, id: number) => {
        commit(mutationsNames.UPDATE_CHECK_OPERATOR_RESET, false);

        const res = await graphqlQueries.operatorResetPassword(id);

        const checkOperatorReset = res.data?.operatorResetPassword.result;
        commit(mutationsNames.UPDATE_CHECK_OPERATOR_RESET, checkOperatorReset);
    },

    [actionsNames.fetchOperator]: async ({ commit }, id: number) => {
        const res = await graphqlQueries.fetchOperator(id);

        const operator = res.data?.operator;
        commit(mutationsNames.UPDATE_OPERATOR, operator);
    },

    [actionsNames.mutateOperator]: async ({ commit }, operatorUpdate: OperatorUpdate) => {
        const res = await graphqlQueries.operatorUpdate(operatorUpdate);

        const operator = res.data?.operatorUpdate.operator;
        commit(mutationsNames.UPDATE_OPERATOR, operator);
    },

    [actionsNames.promoteOperator]: async ({ state, commit }, promoteMutate: OperatorPromote) => {
        commit(mutationsNames.UPDATE_PASSWORD_SERVER_ERROR, '');

        try {
            let res: any;
            let operator: OperatorPromote;

            if (!state.operator.isSuperuser) {
                res = await graphqlQueries.operatorPromote(promoteMutate);

                operator = res.data?.operatorPromote.operator;
            } else {
                res = await graphqlQueries.operatorDemote(promoteMutate);

                operator = res.data?.operatorDemote.operator;
            }

            commit(mutationsNames.UPDATE_OPERATOR, operator);

            commit(mutationsNames.UPDATE_OPERATOR_HANDLE, true);
            setTimeout(() => {
                commit(mutationsNames.UPDATE_OPERATOR_HANDLE, false);
            }, 1000);
        } catch (error) {
            commit(mutationsNames.UPDATE_PASSWORD_SERVER_ERROR, error);
        }
    },

    [actionsNames.manageContentManagerRole]
    : async ({ state, commit }, operatorInput: OperatorPromote) => {
        commit(mutationsNames.UPDATE_PASSWORD_SERVER_ERROR, '');

        try {
            let res: any;
            let operator: OperatorPromote;

            if (!state.operator.isContentManager) {
                res = await graphqlQueries.setContentManager(operatorInput);

                operator = res.data?.setContentManager.operator;
            } else {
                res = await graphqlQueries.unsetContentManager(operatorInput);

                operator = await res.data.unsetContentManager.operator;
            }

            commit(mutationsNames.UPDATE_OPERATOR, operator);

            commit(mutationsNames.UPDATE_OPERATOR_HANDLE, true);
            setTimeout(() => {
                commit(mutationsNames.UPDATE_OPERATOR_HANDLE, false);
            }, 1000);
        } catch (error) {
            commit(mutationsNames.UPDATE_PASSWORD_SERVER_ERROR, error);
        }
    },

    [actionsNames.blockOperator]: async ({ commit }, payload: OperatorPromote) => {
        const res = await graphqlQueries.operatorBlock(payload);
        
        commit(mutationsNames.UPDATE_OPERATOR, res.data?.blockOperator.operator);
    },

    [actionsNames.unBlockOperator]: async ({ commit }, payload: OperatorPromote) => {
        const res = await graphqlQueries.operatorUnblock(payload);

        commit(mutationsNames.UPDATE_OPERATOR, res.data?.unblockOperator.operator);
    },

    [actionsNames.fetchOperators]: async ({ commit }) => {
        const res = await graphqlQueries.fetchOperators();

        const operators = res.data.operators.result;
        const preloader = !operators;

        commit(mutationsNames.UPDATE_OPERATORS, operators);

        commit(mutationsNames.UPDATE_PRELOADER, preloader);
    },
};