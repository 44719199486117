import { ApolloLink, Observable } from 'apollo-boost';

export const onCatch = (errorHandler: any) => new ApolloLink((operation, forward) =>
    new Observable((observer: any) => {
        let subscription: any;
        try {
            subscription = forward(operation).subscribe({
                next: (result) => {
                    if (result.errors) {
                        errorHandler({
                            graphQLErrors: result.errors,
                            response: result,
                            operation,
                        });
                    }
                    observer.next(result);
                },

                error: (error) => {
                    errorHandler({
                        operation,
                        networkError: error,

                        // Network errors can return GraphQL errors on for example a 403
                        graphQLErrors: error.result && error.result.errors,
                    });
                },
                complete: observer.complete.bind(observer),
            });
        } catch (error) {
            errorHandler({ networkError: error, operation });
        }

        return () => {
            if (subscription) subscription.unsubscribe();
        };
    }));