interface Response<T> {
    data?: T;
    detail?: any;
    status: number;
}

interface IGetAllCurrenciesParams {
    page?: string;
    size?: string;
}

interface IUpdateCurrencyParams {
    data: ICurrencyData;
    type: 'create' | 'update';
}

/* eslint-disable */
interface ICurrencyData {
    id?: number;
    name: string;
    code: string;
    symbol: string;
    course: number;
    sort_number: number;
    is_active: boolean;
    dream_limit: number;
}

interface IAllCurrenciesApiData {
    items: ICurrencyData[];
    total: number;
    page: number;
    size: number;
}

enum CurrencyStatuses {
    success = 'success',
    failed = 'failed',
    default = 'default',
    pending = 'pending',
}

interface CurrencyState {
    allCurrencies: ICurrencyData[];
    editStatus: CurrencyStatuses;
}

export {
    Response,
    ICurrencyData,
    CurrencyState,
    IAllCurrenciesApiData,
    IGetAllCurrenciesParams,
    IUpdateCurrencyParams,
    CurrencyStatuses,
};
