
import Vue from 'vue';

import { mapActions, mapMutations, mapState } from 'vuex';

import {
    required,
    minLength,
    email,
} from 'vuelidate/lib/validators';

import { operatorsActions, operatorsMutations } from '@/store/modules/operators/names';

import { ComposedState } from '@/types';

export default Vue.extend({
    name: 'Operators',

    validations: {
        name: { required },
        password: { required, minLength: minLength(8) },
        email: { required, email },
    },

    data() {
        return {
            addOperatorModal: false,
            showPass: false,
            name: '',
            email: '',
            status: true,
            superuser: false,
            contentManager: false,
            password: '',
        };
    },

    computed: {
        ...mapState<ComposedState>({
            operator: (state: ComposedState) => state.operators.operator,
            operators: (state: ComposedState) => state.operators.operators,
            preloader: (state: ComposedState) => state.operators.preloader,
            loaderSetOperator: (state: ComposedState) => state.operators.loaderSetOperator,
            modalStateControl: (state: ComposedState) => state.operators.modalStateControl,
            createEmailError: (state: ComposedState) => state.operators.createEmailError,
        }),

        nameErrors() {
            const errors: string[] = [];
            if (!this.$v.name.$dirty) return errors;

            if (!this.$v.name.required) {
                errors.push('Name is required.');
            }

            return errors;
        },
        emailErrors() {
            const errors: string[] = [];
            if (!this.$v.email.$dirty) return errors;

            if (!this.$v.email.email) {
                errors.push('Email must be valid.');
            }

            if (!this.$v.email.required) {
                errors.push('Email is required.');
            }

            // @ts-ignore
            if (this.createEmailError) {
                // @ts-ignore
                errors.push(this.createEmailError);
            }
            return errors;
        },

        passwordErrors() {
            const errors: string[] = [];

            if (!this.$v.password.$dirty) return errors;

            if (!this.$v.password.minLength) {
                errors.push('Min 8 characters.');
            }
            
            if (!this.$v.password.required) {
                errors.push('Password is required.');
            }

            return errors;
        },
    },

    watch: {
        superuser(newValue) {
            if (newValue) {
                this.contentManager = false;
            }
        },
        contentManager(newValue) {
            if (newValue) {
                this.superuser = false;
            }
        },
    },

    async mounted() {
        await this.fetchOperators();
    },

    methods: {
        ...mapMutations({
            updateCreateEmailError: operatorsMutations.UPDATE_CREATE_EMAIL_ERROR,
        }),

        ...mapActions({
            fetchOperators: operatorsActions.fetchOperators,
            createOperator: operatorsActions.createOperator,
        }),

        async submitForm() {
            this.updateCreateEmailError('');
            this.$v.$touch();

            if (!this.$v.$invalid) {
                await this.createOperator({
                    name: this.name,
                    email: this.email,
                    isActive: this.status,
                    password: this.password,
                    isSuperuser: this.superuser,
                    isContentManager: this.contentManager,
                });

                // @ts-ignore
                this.addOperatorModal = this.modalStateControl;
                if (!this.addOperatorModal) this.resetForm();
            }
        },

        dismissModal() {
            this.updateCreateEmailError('');
            this.addOperatorModal = false;
            this.resetForm();
        },

        resetForm() {
            (this.$refs.addNewOperatpor as HTMLFormElement).reset();
            this.$v.$reset();
        },
    },
});
