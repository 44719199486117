import { ActionTree } from 'vuex';

import rest from '@/api/index';

import { GeneralState, IQueryParamsReview, Upload } from './types';
import { ComposedState } from '@/types/index';

import { actionsNames, mutationsNames } from './names';

export const actions: ActionTree<GeneralState, ComposedState> = {
    async [actionsNames.fetchLangs]({ commit }) {
        commit(mutationsNames.UPDATE_LANGS, null);

        const res = await rest.getAllLanguages();

        commit(mutationsNames.UPDATE_LANGS, res.data);
    },

    async [actionsNames.getCountries]({ commit, state }) {
        if (!state.countries.length) {
            const response = await rest.getCountry();
            if (response && response.data && response.status === 200) {
                commit(mutationsNames.UPDATE_COUNTRIES, response.data);
            }
        }
    },

    [actionsNames.toggleSidebar]({ state, commit }) {
        const drawer = !state.drawer;
        commit(mutationsNames.UPDATE_DRAWER, drawer);
    },

    [actionsNames.uploadUpdate]: ({ commit }, upload: Upload) => {
        commit(mutationsNames.UPDATE_UPLOAD, upload);
    },

    [actionsNames.uploadReset]: ({ commit }) => {
        commit(mutationsNames.UPDATE_UPLOAD, {
            process: false,
            loading: true,
            check: false,
        });
    },

    [actionsNames.setErrors]({ commit }, errors: string[]) {
        commit(mutationsNames.UPDATE_ERRORS, errors);
    },

    async [actionsNames.getReviews]({ commit }, params: IQueryParamsReview) {
        const response = await rest.getReviews(params);

        if (response && response.status === 200) {
            commit(mutationsNames.SET_REVIEWS, response.data?.items);
            commit(mutationsNames.SET_REVIEWS_TOTAL, response.data?.total);
        }
    },
};