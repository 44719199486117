import { ActionTree } from 'vuex';

import usersServices from './services';

import rest from '@/api/index';
import graphqlQueries from '@/api/index.graphql';

import {
    UsersState,
    UserCreate,
    EditUser,
    UserBlock,
    UserUnblock,
    UserDelete,
    QueryProlongPay,
    PaymentData,
    UserPutData,
    QueryLoginAsLink,
    UsersQueryType,
    IParams,
} from './types';

import { ComposedState } from '@/types/index';

import { actionsNames, mutationsNames } from './names';
import { generalActions } from '../general/names';

export const actions: ActionTree<UsersState, ComposedState> = {
    [actionsNames.createUser]: async ({ commit, dispatch }, userCreate: UserCreate) => {
        let res: any = null;

        try {
            dispatch(
                generalActions.uploadUpdate,
                {
                    process: true,
                    loading: true,
                    check: false,
                },
                { root: true },
            );

            res = await graphqlQueries.userCreate(userCreate);
        } catch (error) {
            dispatch(
                generalActions.uploadReset,
                {
                    process: false,
                    loading: true,
                    check: false,
                },
                { root: true },
            );
            return;
        }

        if (res !== null) {
            dispatch(
                generalActions.uploadUpdate,
                {
                    process: true,
                    loading: false,
                    check: true,
                },
                { root: true },
            );

            commit(mutationsNames.UPDATE_USER, res.data);
        }
    },

    async [actionsNames.fetchPayments]({ commit }, id: number) {
        let res;

        commit(mutationsNames.UPDATE_LOADED_STATUS, false);

        try {
            res = await graphqlQueries.fetchPayments(id);
        } catch (error) {
            return;
        }

        if (res !== null) {
            const payments = res.data.user;
            commit(mutationsNames.UPDATE_PAYMENTS, payments);

            commit(mutationsNames.UPDATE_LOADED_STATUS, true);
        }
    },

    async [actionsNames.fetchUser]({ commit }, id: number) {
        commit(mutationsNames.UPDATE_LOADED_STATUS, false);
        commit(mutationsNames.UPDATE_USER, null);

        const res = await rest.getUserInfo(id);

        if (res) {
            commit(mutationsNames.UPDATE_USER, res.data);
        }
        
        commit(mutationsNames.UPDATE_LOADED_STATUS, true);

        return res;
    },

    // eslint-disable-next-line 
    async [actionsNames.setVipStatus]({ commit }, params: { user_id: number, is_vip: boolean }) {
        const res = await rest.setVipStatus(params);

        if (!res) return;

        commit(mutationsNames.SET_VIP_STATUS, params.is_vip);
    },

    async [actionsNames.editUser]({ commit }, editUser: EditUser) {
        const res = await usersServices.userEmailChange(editUser);

        if (res) {
            commit(mutationsNames.UPDATE_USER_EMAIL, res.data?.userChangeEmail.user.email);
        }
    },

    async [actionsNames.blockUser]({ commit }, params: UserBlock) {
        const res = await rest.blockUser(params);

        if (!res) return;
        
        commit(mutationsNames.UPDATE_USER_STATUS, res.data?.is_active);
        
        return res;
    },

    async [actionsNames.unblockUser]({ commit }, params: UserUnblock) {
        const res = await rest.unblockUser(params);

        if (!res) return;
        
        commit(mutationsNames.UPDATE_USER_STATUS, res.data?.is_active);
        
        return res;
    },

    async [actionsNames.deleteUser]({ commit }, params: UserDelete) {
        const res = await rest.deleteUser(params);

        if (!res) return;

        commit(mutationsNames.DELETE_USER, res.data?.is_delete);
        
        return res;
    },

    async [actionsNames.postProlongPay]({}, params: QueryProlongPay) {
        return usersServices.postProlongPay(params);
    },

    async [actionsNames.rollbackPayment]({}, donationId: number) {
        return usersServices.rollbackPayment(donationId);
    },

    async [actionsNames.paymentDataOrderUpdate]({}, params: PaymentData) {
        return usersServices.paymentDataOrderUpdate(params);
    },

    async [actionsNames.updateUserInfo]({}, params: UserPutData) {
        return usersServices.userEdit(params);
    },

    async [actionsNames.loginAsLink]({}, params: QueryLoginAsLink) {
        return usersServices.loginAsLink(params);
    },

    async [actionsNames.fetchUsers]({ commit }, query: UsersQueryType) {
        const res = await rest.getUsers(query);

        if (!res) return;
        
        const users = res.data?.items;
        const count = res.data?.total;
        commit(mutationsNames.UPDATE_USERS, users);
        commit(mutationsNames.UPDATE_USERS_COUNT, count);
    },

    async [actionsNames.fetchSpecialUsers]({ commit }, params: IParams) {
        const res = await rest.getSpecialUsers(params);

        if (!res) return;

        commit(mutationsNames.UPDATE_SPECIAL_USERS, res.data?.items);
        commit(mutationsNames.SET_SPECIAL_USERS_TOTAL, res.data?.total);
    },

    async [actionsNames.getDonations]({ commit }, params) {
        const res = await usersServices.getDonations(params);
        
        if (res) {
            commit(mutationsNames.SET_DONATIONS, res.data.donations);
        }
    },

    async [actionsNames.changeSubscribe]({}, params) {
        return rest.changeSubscribe(params.id, params.subscribe_till, params.dreams_to_activate);
    },
};