import { actions } from './actions';
import { mutations } from './mutations';
import { DashboardState } from './types';

const state: DashboardState = {
    activeUsers: null,
    countryStatistic: [],
};

export default {
    namespaced: true,
    state: () => state,
    actions,
    mutations,
};