import { actions } from './actions';
import { mutations } from './mutations';
import { DonationState } from './types';

const state: DonationState = {
    donation: [],
    donationUser: [],
    donationTotal: 0,
};

export default {
    namespaced: true,
    state: () => state,
    actions,
    mutations,
};