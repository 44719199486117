
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';  

import { generalActions } from '@/store/modules/general/names';

import { ComposedState } from '@/types';

export default Vue.extend({
    name: 'LanguagesSelector',

    props: {
        select: String,
    },

    data() {
        return {            
            lang: null as null | string,
        };
    },

    computed: {
        ...mapState<ComposedState>({
            langs: (state: ComposedState) => state.general.langs,
        }),

        langsKeys(): string[] {
            // @ts-ignore
            return Object.keys({ ...this.langs });
        },
    },

    watch: {
        select(val) {                     
            if (val === null) {
                this.lang = null;
                return;
            }

            this.lang = val;        
        },
    },

    async mounted() {      
        await this.fetchLangs();

        if (this.select) {
            this.lang = this.select; 
        }
    },

    methods: {
        ...mapActions({        
            fetchLangs: generalActions.fetchLangs,
        }),

        updateSelector(newValue) {               
            this.$emit('update:select', newValue);
        },
    },
});
