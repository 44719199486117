import { apolloClient } from '@/plugins/apollo/index';

import { ApolloQueryResult, FetchResult } from 'apollo-boost';

import {
    DASHBOARD,
    USER_CHANGE_EMAIL,
    PROLONG_PAYMENT,
    ROLLBACKPAYMENT,
    PAYMENT_DATA_ORDER_UPDATE,
    USER_EDIT,
    LOAGIN_AS_LINK,
    GET_ME,
    OPERATOR_CREATE,
    OPERATOR_ITEM,
    OPERATOR_UPDATE,
    OPERATOR_PROMOTE,
    OPERATOR_DEMOTE,
    SET_CONTENT_MANAGER,
    UNSET_CONTENT_MANAGER,
    OPERATOR_RESET_PASSW0RD,
    OPERATOR_BLOCK,
    OPERATOR_UNBLOCK,
    OPERATORS_ALL,
    USER_CREATE,
    PAYMENTS,
    GET_DONATIONS,
} from './graphql/shemes';

import {
    IResponseDashboard,
    IResponseUserEmailChange,
    IResponsePrologPayment,
    IResponseRollbackPayment,
    IResponsePaymentDataOrderUpdate,
    IResponseUserEdit,
    IResponseLoginAsLink,
    IResponseFetchAdmin,
    IResponseOperatorCreate,
    IResponseOperatorResetPassword,
    IResponseFetchOperator,
    IResponseOperatorUpdate,
    IResponseOperatorPromote,
    IResponseOperatorDemote,
    IResponseSetContentManager,
    IResponseUnsetContentManager,
    IResponseOperatorBlock,
    IResponseOperatorUnblock,
    IResponseFetchOperators,
    IResponseUserCreate,
    IResponseUserPayments,
    IResponseDonations,
} from './types';

import {
    QueryProlongPay,
    PaymentData,
    UserPutData,
    QueryLoginAsLink,
    EditUser,
    UserCreate,
} from '@/store/modules/users/types';

import { OperatorCreate, OperatorPromote, OperatorUpdate } from '@/store/modules/operators/types';

export default {
    async getDashboard(): Promise<ApolloQueryResult<IResponseDashboard>> {
        try {
            return apolloClient.query({
                query: DASHBOARD,
            });
        
        // @ts-ignore
        } catch (error: Error) {
            return error;
        }
    },

    async userEmailChange(params: EditUser): Promise<FetchResult<IResponseUserEmailChange>> {
        return apolloClient.mutate({
            mutation: USER_CHANGE_EMAIL,
            variables: {
                input: params,
            },
        });
    },

    async postProlongPay(params: QueryProlongPay): Promise<FetchResult<IResponsePrologPayment>> {
        return apolloClient.query({
            query: PROLONG_PAYMENT,
            variables: {
                input: params,
            },
        });
    },

    async rollbackPayment(params: { donationId: number })
        : Promise<FetchResult<IResponseRollbackPayment>> {
        return apolloClient.query({
            query: ROLLBACKPAYMENT,
            variables: {
                input: params,
            },
        });
    },

    async paymentDataOrderUpdate(params: PaymentData)
        : Promise<FetchResult<IResponsePaymentDataOrderUpdate>> {
        return apolloClient.mutate({
            mutation: PAYMENT_DATA_ORDER_UPDATE,
            variables: {
                input: params,
            },
        });
    },

    async userEdit(params: UserPutData): Promise<FetchResult<IResponseUserEdit>> {    
        return apolloClient.mutate({
            mutation: USER_EDIT,
            variables: {
                input: params,
            },
        });
    },

    async loginAsLink(params: QueryLoginAsLink): Promise<FetchResult<IResponseLoginAsLink>> {  
        const res = await apolloClient.mutate({
            mutation: LOAGIN_AS_LINK,
            variables: {
                input: params,
            },
        });

        return res.data;
    },

    async fetchAdmin(): Promise<ApolloQueryResult<IResponseFetchAdmin>> {
        return apolloClient.query({
            query: GET_ME,
        });
    },

    async operatorCreate(params: OperatorCreate)
        : Promise<FetchResult<IResponseOperatorCreate>> {
        return apolloClient.mutate({
            mutation: OPERATOR_CREATE,
            variables: {
                input: {
                    name: params.name,
                    email: params.email,
                    isActive: params.isActive,
                    password: params.password,
                    isSuperuser: params.isSuperuser,
                    isContentManager: params.isContentManager,
                },
            },
        });
    },

    async operatorResetPassword(id: number): Promise<FetchResult<IResponseOperatorResetPassword>> {
        return apolloClient.mutate({
            mutation: OPERATOR_RESET_PASSW0RD,
            variables: {
                input: {
                    id,
                },
            },
        });
    },

    async fetchOperator(id: number): Promise<ApolloQueryResult<IResponseFetchOperator>> {
        return apolloClient.query({
            query: OPERATOR_ITEM,
            variables: {
                input: {
                    id,
                },
            },
        });
    },

    async operatorUpdate(params: OperatorUpdate): Promise<FetchResult<IResponseOperatorUpdate>> {
        return apolloClient.mutate({
            mutation: OPERATOR_UPDATE,
            variables: {
                input: params,
            },
        });
    },
    
    async operatorPromote(params: OperatorPromote): Promise<FetchResult<IResponseOperatorPromote>> {
        return apolloClient.mutate({
            mutation: OPERATOR_PROMOTE,
            variables: {
                input: params,
            },
        });
    },

    async operatorDemote(params: OperatorPromote): Promise<FetchResult<IResponseOperatorDemote>> {
        return apolloClient.mutate({
            mutation: OPERATOR_DEMOTE,
            variables: {
                input: params,
            },
        });
    },

    async setContentManager(params: OperatorPromote)
        : Promise<FetchResult<IResponseSetContentManager>> {
        return apolloClient.mutate({
            mutation: SET_CONTENT_MANAGER,
            variables: {
                input: params,
            },
        });
    },
    
    async unsetContentManager(params: OperatorPromote)
        : Promise<FetchResult<IResponseUnsetContentManager>> {
        return apolloClient.mutate({
            mutation: UNSET_CONTENT_MANAGER,
            variables: {
                input: params,
            },
        });
    },

    async operatorBlock(params: OperatorPromote): Promise<FetchResult<IResponseOperatorBlock>> {
        return apolloClient.mutate({
            mutation: OPERATOR_BLOCK,
            variables: {
                input: params,
            },
        });
    },

    async operatorUnblock(params: OperatorPromote)
        : Promise<FetchResult<IResponseOperatorUnblock>> {
        return apolloClient.mutate({
            mutation: OPERATOR_UNBLOCK,
            variables: {
                input: params,
            },
        });
    },

    async fetchOperators(): Promise<ApolloQueryResult<IResponseFetchOperators>> {
        return apolloClient.query({
            query: OPERATORS_ALL,
            variables: {
                input: {
                    query: '',
                },
            },
        });
    },

    async userCreate(params: UserCreate): Promise<FetchResult<IResponseUserCreate>> {
        return apolloClient.mutate({
            mutation: USER_CREATE,
            variables: {
                input: params,
            },
        });
    },

    async fetchPayments(id: number): Promise<ApolloQueryResult<IResponseUserPayments>> {
        return apolloClient.query({
            query: PAYMENTS,
            variables: {
                input: {
                    id,
                },
            },
        });
    },

    async getDonations(params): Promise<ApolloQueryResult<IResponseDonations>> {
        return apolloClient.query({
            query: GET_DONATIONS,
            variables: {
                input: params,
            },
        });
    },
};