import { QueryParams, Response } from '@/types/index';
import { News } from './types';

import rest from '@/api/index';
import { IResponseWithItems } from '@/api/types';

const newServices = {
    async fetchNewsList(params?: QueryParams): Promise<Response<IResponseWithItems<News[]>>> {
        return rest.fetchNewsList(params);
    },

    async createNews(params: FormData): Promise<Response<any>> {
        return rest.createNews(params);
    },

    async updateNews(params: any, id: any): Promise<Response<any>> {
        return rest.updateNews(params, id);
    },

    async deleteNews({ id }: {id: number} ): Promise<Response<any>> {
        return rest.deleteNews({ id });
    },
};

export default newServices;