import { addModuleName } from '@/tools/addModuleNames';

const MODULE_NAME = 'dashboard';

/* eslint-disable */
enum mutationsNames {
    UPDATE_ACTIVE_USERS = 'UPDATE_ACTIVE_USERS',
    UPDATE_COUNTRY_STATISTIC = 'UPDATE_COUNTRY_STATISTIC',
}

enum actionsNames {
    getDashboard = 'getDashboard',
}
/* eslint-enable */

const dashboardMutations = {
    [mutationsNames.UPDATE_ACTIVE_USERS]: addModuleName(
        MODULE_NAME,
        mutationsNames.UPDATE_ACTIVE_USERS,
    ),
    [mutationsNames.UPDATE_COUNTRY_STATISTIC]: addModuleName(
        MODULE_NAME,
        mutationsNames.UPDATE_COUNTRY_STATISTIC,
    ),
};

const dashboardActions = {
    [actionsNames.getDashboard]: addModuleName(
        MODULE_NAME,
        actionsNames.getDashboard,
    ),
};

export {
    mutationsNames,
    dashboardMutations,
    actionsNames,
    dashboardActions,
};