import { MutationTree } from 'vuex';
import { mutationsNames } from './names';
import { CurrencyState, CurrencyStatuses, ICurrencyData } from './types';

export const mutations: MutationTree<CurrencyState> = {
    [mutationsNames.SET_ALL_CURRENCIES](state, payload: ICurrencyData[]) {
        state.allCurrencies = payload;
    },
    [mutationsNames.SET_EDIT_CURRENCY_STATUS](state, payload: CurrencyStatuses) {
        state.editStatus = payload;
    },
};