import { ActionTree } from 'vuex';

import rest  from '@/api/index';

import {
    CurrencyState,
    CurrencyStatuses,
    IGetAllCurrenciesParams,
    IUpdateCurrencyParams,
} from './types';

import { ComposedState } from '@/types/index';

import { actionsNames, mutationsNames } from './names';

export const actions: ActionTree<CurrencyState, ComposedState> = {
    async [actionsNames.getAllCurrencies]({ commit }, params: IGetAllCurrenciesParams) {
        const res = await rest.getAllCurrencies(params);

        if (res && res.status === 200 && res.data) {
            commit(mutationsNames.SET_ALL_CURRENCIES, res.data.items);
        }
    },

    async [actionsNames.updateCurrency]({ commit, dispatch }, params: IUpdateCurrencyParams) {
        commit(mutationsNames.SET_EDIT_CURRENCY_STATUS, CurrencyStatuses.default);

        let res;

        switch (params.type) {
            case 'update':
                res = await rest.updateCurrency(params.data);
                break;
            case 'create':
                res = await rest.createCurrency(params.data);
                break;
            default:
                break;
        }

        if (res && !res.detail) {
            commit(mutationsNames.SET_EDIT_CURRENCY_STATUS, CurrencyStatuses.success);
            dispatch(actionsNames.getAllCurrencies);
        } else {
            commit(mutationsNames.SET_EDIT_CURRENCY_STATUS, CurrencyStatuses.failed);
        }
    },
};