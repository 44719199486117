import { actions } from './actions';
import { mutations } from './mutations';
import { AdminState } from './types';

const state: AdminState = {
    admin: {
        id: 0,
        name: '',
        email: '',
        isActive: false,
        isContentManager: false,
        isSuperuser: false,
    },
    access: null,
    preloader: false,
};

export default {
    namespaced: true,
    state: () => state,
    actions,
    mutations,
};