import '@mdi/font/css/materialdesignicons.css';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import Vue from 'vue';
import { Theme } from '@/types';
import theme from './theme';
import Vuetify from 'vuetify/lib';

type Icons = {
  iconfont: any;
  theme: Theme;
};

const icons: Icons = {
    iconfont: 'mdi',
    theme,
};

Vue.use(Vuetify);

export default new Vuetify({
    icons,
});
