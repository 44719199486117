import { IReview } from '@/types';
import { MutationTree } from 'vuex';
import { mutationsNames } from './names';
import { GeneralState, ICountry, ILang, Upload } from './types';

export const mutations: MutationTree<GeneralState> = {
    [mutationsNames.UPDATE_LANGS](state, langs: ILang[] ) {
        state.langs = langs;
    },

    [mutationsNames.UPDATE_COUNTRIES](state, countries: ICountry[]) {
        state.countries = countries;
    },

    [mutationsNames.TOGGLE_INIT_STATUS](state, payload: boolean) {
        state.initialization = payload;
    },

    [mutationsNames.TOGGLE_GLOBAL_LOADER](state, payload: boolean) {
        state.loader = payload;
    },

    [mutationsNames.UPDATE_DRAWER](state, drawer: boolean) {
        state.drawer = drawer;
    },

    [mutationsNames.UPDATE_UPLOAD](state, upload: Upload) {
        state.upload = upload;
    },

    [mutationsNames.UPDATE_ERRORS](state, error: string) {
        state.errors.push(error);
    },

    [mutationsNames.RESET_ERRORS](state) {
        state.errors = [];
    },

    [mutationsNames.UPDATE_STANDART_ERRORS](state, errors: string[]) {
        state.standartErrors = errors;
    },

    [mutationsNames.SET_REVIEWS](state, payload: IReview[]) {
        state.reviews = payload;
    },

    [mutationsNames.SET_REVIEWS_TOTAL](state, payload: number) {
        state.reviewsTotal = payload;
    },
};