import { MutationTree } from 'vuex';
import { mutationsNames } from './names';
import { CountryStatistic, DashboardState } from './types';

export const mutations: MutationTree<DashboardState> = {
    [mutationsNames.UPDATE_ACTIVE_USERS](state, activeUsers: number) {
        state.activeUsers = activeUsers;
    },
    [mutationsNames.UPDATE_COUNTRY_STATISTIC](state, countryStatistic: CountryStatistic[]) {
        state.countryStatistic = countryStatistic;
    },
};