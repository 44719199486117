import { addModuleName } from '@/tools/addModuleNames';

const MODULE_NAME = 'general';

/* eslint-disable */
enum mutationsNames {
    UPDATE_LANGS = 'UPDATE_LANGS',
    UPDATE_COUNTRIES = 'UPDATE_COUNTRIES',
    TOGGLE_INIT_STATUS = 'TOGGLE_INIT_STATUS',
    TOGGLE_GLOBAL_LOADER = 'TOGGLE_GLOBAL_LOADER',
    UPDATE_DRAWER = 'UPDATE_DRAWER',
    UPDATE_UPLOAD = 'UPDATE_UPLOAD',
    UPDATE_ERRORS = 'UPDATE_ERRORS',
    RESET_ERRORS = 'RESET_ERRORS',
    UPDATE_STANDART_ERRORS = 'UPDATE_STANDART_ERRORS',
    SET_REVIEWS = 'SET_REVIEWS',
    SET_REVIEWS_TOTAL = 'SET_REVIEWS_TOTAL',
}

enum actionsNames {
    fetchLangs = 'fetchLangs',
    getCountries = 'getCountries',
    toggleSidebar = 'toggleSidebar',
    uploadUpdate = 'uploadUpdate',
    uploadReset = 'uploadReset',
    setErrors = 'setErrors',
    getReviews = 'getReviews',
}
/* eslint-enable */

const generalMutations = {
    [mutationsNames.UPDATE_LANGS]: addModuleName(
        MODULE_NAME,
        mutationsNames.UPDATE_LANGS,
    ),
    [mutationsNames.UPDATE_COUNTRIES]: addModuleName(
        MODULE_NAME,
        mutationsNames.UPDATE_COUNTRIES,
    ),
    [mutationsNames.TOGGLE_INIT_STATUS]: addModuleName(
        MODULE_NAME,
        mutationsNames.TOGGLE_INIT_STATUS,
    ),
    [mutationsNames.TOGGLE_GLOBAL_LOADER]: addModuleName(
        MODULE_NAME,
        mutationsNames.TOGGLE_GLOBAL_LOADER,
    ),
    [mutationsNames.UPDATE_DRAWER]: addModuleName(
        MODULE_NAME,
        mutationsNames.UPDATE_DRAWER,
    ),
    [mutationsNames.UPDATE_UPLOAD]: addModuleName(
        MODULE_NAME,
        mutationsNames.UPDATE_UPLOAD,
    ),
    [mutationsNames.UPDATE_ERRORS]: addModuleName(
        MODULE_NAME,
        mutationsNames.UPDATE_ERRORS,
    ),
    [mutationsNames.RESET_ERRORS]: addModuleName(
        MODULE_NAME,
        mutationsNames.RESET_ERRORS,
    ),
    [mutationsNames.UPDATE_STANDART_ERRORS]: addModuleName(
        MODULE_NAME,
        mutationsNames.UPDATE_STANDART_ERRORS,
    ),
    [mutationsNames.SET_REVIEWS]: addModuleName(
        MODULE_NAME,
        mutationsNames.SET_REVIEWS,
    ),
    [mutationsNames.SET_REVIEWS_TOTAL]: addModuleName(
        MODULE_NAME,
        mutationsNames.SET_REVIEWS_TOTAL,
    ),
};

const generalActions = {
    [actionsNames.fetchLangs]: addModuleName(
        MODULE_NAME,
        actionsNames.fetchLangs,
    ),
    [actionsNames.getCountries]: addModuleName(
        MODULE_NAME,
        actionsNames.getCountries,
    ),
    [actionsNames.toggleSidebar]: addModuleName(
        MODULE_NAME,
        actionsNames.toggleSidebar,
    ),
    [actionsNames.uploadUpdate]: addModuleName(
        MODULE_NAME,
        actionsNames.uploadUpdate,
    ),
    [actionsNames.uploadReset]: addModuleName(
        MODULE_NAME,
        actionsNames.uploadReset,
    ),
    [actionsNames.setErrors]: addModuleName(
        MODULE_NAME,
        actionsNames.setErrors,
    ),
    [actionsNames.getReviews]: addModuleName(
        MODULE_NAME,
        actionsNames.getReviews,
    ),
};

export {
    mutationsNames,
    generalMutations,
    actionsNames,
    generalActions,
};