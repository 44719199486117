import { MutationTree } from 'vuex';
import { mutationsNames } from './names';
import { NewsState } from './types';

export const mutations: MutationTree<NewsState> = {
    [mutationsNames.UPDATE_NEWS](state, { news, count }) {
        state.news = news;
        state.count = count;
    },

    [mutationsNames.SET_LANGUAGE_OF_LAST_CREATED_NEW](state, language: string) {
        state.languageOfLastCreatedNew = language;
    },
};