import { addModuleName } from '@/tools/addModuleNames';

const MODULE_NAME = 'currency';

/* eslint-disable */
enum mutationsNames {
    SET_ALL_CURRENCIES = 'SET_ALL_CURRENCIES',
    SET_EDIT_CURRENCY_STATUS = 'SET_EDIT_CURRENCY_STATUS',
}

enum actionsNames {
    getAllCurrencies = 'getAllCurrencies',
    updateCurrency = 'updateCurrency',
}
/* eslint-enable */

const currencyMutations = {
    [mutationsNames.SET_ALL_CURRENCIES]: addModuleName(
        MODULE_NAME,
        mutationsNames.SET_ALL_CURRENCIES,
    ),
    [mutationsNames.SET_EDIT_CURRENCY_STATUS]: addModuleName(
        MODULE_NAME,
        mutationsNames.SET_EDIT_CURRENCY_STATUS,
    ),
};

const currencyActions = {
    [actionsNames.getAllCurrencies]: addModuleName(
        MODULE_NAME,
        actionsNames.getAllCurrencies,
    ),
    [actionsNames.updateCurrency]: addModuleName(
        MODULE_NAME,
        actionsNames.updateCurrency,
    ),
};

export {
    mutationsNames,
    currencyMutations,
    actionsNames,
    currencyActions,
};