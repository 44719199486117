
import Vue from 'vue';

import {
    mapActions,
    mapMutations,
    mapState,
} from 'vuex';

import { validationMixin } from 'vuelidate';

import {
    required,
    minLength,
    email,
} from 'vuelidate/lib/validators';

import { generalActions, generalMutations } from '@/store/modules/general/names';
import { usersActions } from '@/store/modules/users/names';

import { ComposedState } from '@/types';

export default Vue.extend({
    name: 'UserCreate',

    mixins: [validationMixin],

    validations: {
        create: {
            name: { required },
            surname: { required },
            email: { required, email },
            password: { required, minLength: minLength(8) },
            country: { required },
            isFemale: { required },
        },
    },

    data() {
        return {
            datepickerModal: false,

            create: {
                name: null,
                surname: null,
                email: '',
                password: null,
                birthDate: null,
                country: null,
                isFemale: null,
            },

            createUserModal: false,
            loading: true,
            emailRegExp: /^[a-zA-Z]{1}([\w\.\-]*)@(?![-.])([\w\-]+)((\.(\w){2,})+)$/,
        };
    },

    computed: {
        ...mapState<ComposedState>({
            admin: (state: ComposedState) => state.admin.admin,
            countries: (state: ComposedState) => state.general.countries,
            uploadErrors: (state: ComposedState) => state.general.errors,
            upload: (state: ComposedState) => state.general.upload,
        }),
        nameErrors() {
            const errors: string[] = [];
            if (!this.$v.create.name?.$dirty) return errors;
            !this.$v.create.name.required && errors.push('Name is required.');
            return errors;
        },
        surnameErrors() {
            const errors: string[] = [];
            if (!this.$v.create.surname?.$dirty) return errors;
            !this.$v.create.surname.required && errors.push('Surname is required.');
            return errors;
        },
        emailErrors() {
            const errors: string[] = [];
            if (!this.$v.create.email?.$dirty) return errors;

            !this.$v.create.email.required && errors.push('Email is required.');

            if (!this.emailRegExp.test(this.create.email)) {
                errors.push('Incorrect email');
            }

            return errors;
        },
        passwordErrors() {
            const errors: string[] = [];
            if (!this.$v.create.password?.$dirty) return errors;
            if (!this.$v.create.password.minLength) {
                errors.push('Min 8 characters.');
            }
            if (!this.$v.create.password.required) {
                errors.push('Password is required.');
            }
            return errors;
        },

        countryErrors() {
            const errors: string[] = [];
            if (!this.$v.create.country?.$dirty) return errors;
            !this.$v.create.country.required && errors.push('Country is required.');
            return errors;
        },

        countryIsFemale() {
            const errors: string[] = [];
            if (!this.$v.create.isFemale?.$dirty) return errors;
            !this.$v.create.isFemale.required && errors.push('Sex is required.');
            return errors;
        },
    },

    async mounted() {
        await this.getCountries();
    },

    methods: {
        ...mapActions({
            getCountries: generalActions.getCountries,
            createUser: usersActions.createUser,
            uploadReset: generalActions.uploadReset,
        }),

        ...mapMutations({
            resetErrors: generalMutations.RESET_ERRORS,
        }),

        scrollToErrors() {
            const container = document.querySelector('.v-dialog');
            container ? container.scrollTop = 0 : null;
        },

        async addUser() {
            this.$v.create.$touch();

            this.resetErrors();

            if (!this.$v.create.$error) {
                await this.createUser(this.create);

                // @ts-ignore
                if (this.upload.check) {
                    setTimeout(() => {
                        this.createUserModal = false;

                        (this.$refs.addNewUser as HTMLFormElement).reset();
                        this.$v.create.$reset();

                        this.resetErrors();
                        this.uploadReset();
                    }, 800);
                } else {
                    this.uploadReset();
                    this.scrollToErrors();
                }
            }
        },
    },
});
