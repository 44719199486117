
import Vue from 'vue';

import { mapActions, mapState } from 'vuex';

import { usersActions } from '@/store/modules/users/names';

import CreateFormUser from './UserCreate.vue';

import { ComposedState } from '@/types';
import { IParams } from '@/store/modules/users/types';

interface IOptions {
    page: number;
    itemsPerPage: number;
};

export default Vue.extend({
    name: 'UsersWithStatus',

    components: {
        CreateFormUser,
    },

    data() {
        return {
            paginConfig: {
                page: 1,
                itemsPerPage: 10,
            },
            search: '',
            options: {} as IOptions,
            loading: true,
            footerProps: {
                'items-per-page-options': [2, 5, 10, 15],
            },
            headers: [
                {
                    text: 'Name',
                    align: 'left',
                    sortable: false,
                    value: 'name',
                },
                { text: 'Id', value: 'id' },
                { text: 'Status', value: 'isActive' },
            ],
        };
    },

    computed: {
        ...mapState<ComposedState>({
            specialUsersTotal: (state: ComposedState) => state.users.specialUsersTotal,
            users: (state: ComposedState) => state.users.specialUsers,
            count: (state: ComposedState) => state.users.count,
        }),
    },

    watch: {
        search: {
            handler() {
                this.options.page = 1;

                this.querySortUser().then(() => {
                    this.loading = false;
                });
            },
            deep: true,
        },

        options: {
            handler() {
                this.querySortUser().then(() => {
                    this.loading = false;
                });
            },
            deep: true,
        },
    },

    mounted() {
        if (this.$route.query.page && this.$route.query.size) {
            this.fetchSpecialUsers({
                page: this.$route.query.page,
                size: this.$route.query.size,
            });

            this.options.page = +this.$route.query.page;
            this.options.itemsPerPage = +this.$route.query.size;

            return;
        }

        this.$router.push({ query: { page: '1', size: '10' }});
        this.fetchSpecialUsers({ page: 1, size: 10 });
    },

    methods: {
        ...mapActions({
            fetchUsers: usersActions.fetchUsers,
            fetchSpecialUsers: usersActions.fetchSpecialUsers,
        }),

        async querySortUser() {
            this.loading = true;

            const { page, itemsPerPage } = this.options;

            const params = {
                size: itemsPerPage,
                page,
                search: this.search ? this.search : null, 
            } as IParams;

            await this.fetchSpecialUsers(params);
        },
    },
});
