

// @ts-nocheck

import Vue from 'vue';

import rest from '@/api/index';
import { required } from 'vuelidate/lib/validators';

export default Vue.extend({
    name: 'GenerationTamplate',

    props: {
        ConfigData: {
            type: Object,
            required: true,
        },
        GenerationManager: {
            type: Object,
            required: true,
        },
    },

    validations: {
        transformedData: {
            min: {
                required,
                minLength(value) {
                    return value > 0;
                },
                
                maxLength(value) {
                    return +value < +this.transformedData.max;
                },
            },
            max: {
                required,

                minLength(value) {
                    return +value > +this.transformedData.min;
                },
            },
            days: { 
                required,
                minLength(value) {
                    return +value > 0;
                },
                maxLength(value) {
                    return +value < 366;
                },
            },
        },
    },

    data() {
        return {
            transformedData: null,
            type: this.GenerationManager.managerType,
            id: this.GenerationManager.id,
        };
    },

    computed: {
        errorRangeFrom(): string  {
            let error: string = '';
            if (!this.$v.transformedData.min?.$dirty) return error;
            !this.$v.transformedData.min.required && (error = '"Диапазон от" обязателен.');
            !this.$v.transformedData.min.minLength
                && (error = 'Значение "Диапазон от" не соответствует минимальному');
            !this.$v.transformedData.min.maxLength
                && (error = 'Макс. длина больше "Диапазон до"');
            return error;
        },

        errorRangeTo(): string  {
            let error: string = '';
            if (!this.$v.transformedData.max?.$dirty) return error;
            !this.$v.transformedData.max.required
                && (error = '"Диапазон до" обязателен.');
            !this.$v.transformedData.max.minLength
                && (error = 'Мин. длина меньше "Диапазон от"');
            return error;
        },

        errorDays(): string  {
            let error: string = '';
            if (!this.$v.transformedData.days?.$dirty) return error;
            !this.$v.transformedData.days.required
                && (error = '"Время на модификацию" обязательно.');
            !this.$v.transformedData.days.minLength
                && (error = 'Мин. длина меньше 1');
            !this.$v.transformedData.days.maxLength
                && (error = 'Макс. длина больше года');
            return error;
        },

        disableBtn(): boolean {
            return !!this.errorRangeFrom
                || !!this.errorRangeTo
                || !!this.errorDays
                || this.$v.transformedData.$invalid; 
        },
    },

    created() { 
        this.transformedData = this.transformConfig(this.$props.ConfigData);
    },

    async mounted() {
        const getConfigFunction = this.type === 'view' 
            ? rest.getViewsConfig : rest.getLikesConfig;

        if (getConfigFunction) {
            const res = await getConfigFunction(this.id);
            if (res.data) {
                this.transformedData = this.transformConfig(res.data);
            }
        }
    },

    methods: {
        transformConfig(config: Object) {
            return {
                min: config.min_views || config.min_likes,
                max: config.max_views || config.max_likes,
                is_active: config.is_active,
                days: config.days,
            };
        },
        
        async modified() {
            const isView = this.type === 'view';
            const sendConfigFunction = isView 
                ? rest.sendViewsConfig : rest.sendLikesConfig;

            const res = await sendConfigFunction(this.id, {
                days: +this.transformedData.days,
                is_active: this.transformedData.is_active,
                [`${isView ? 'min_views' : 'min_likes'}`]: +this.transformedData.min,
                [`${isView ? 'max_views' : 'max_likes'}`]: +this.transformedData.max,             
            });

            if (res && res.status === 200) {
                window.location.reload();
            }
        },  
        
        changeStatus() {
            this.transformedData.is_active = !this.transformedData.is_active;
        },
    },
});
