
import Vue from 'vue';

export default Vue.extend({
    name: 'BlockUser',

    props: {
        userBlockStstus: Boolean,
        blockUserModal: Function,
        blockUserName: String,
        vldRules: Array,
        blockUser: Function,
    },
});
