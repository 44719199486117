import { IRelations } from '@/types';

interface Response<T> {
    data?: T;
    status: number;
}

/* eslint-disable */
interface IDreamItem {
    category: {
        id: number;
        title_cat: string;
    };
    closed_at: number | null;
    collected: number;
    created_at: string;
    likes: number;
    views: number;
    id: number;
    goal: number;
    title: string;
    type_dream: string;
    user: {
        avatar: string;
        country_id: number;
        id: number;
        name: string;
        refer_code: string;
        refer_count: null | number;
        surname: string;
    };
}

interface IExtendedDreamItem extends IDreamItem {
    description: string;
    picture: string;
}

interface IChangeDreamItem {
    type_dream?: string;
    title?: string;
    user_id?: number;
    description?: string;
    category_id?: number;
    goal?: number;
    likes?: number;
    views?: number;
    picture?: any;
}

interface ICreateDreamUserCurrency {
    id: number;
    code: string;
    symbol: string;
    name: string;
    course: number;
    sort_number: number;
    is_active: true;
    dream_limit: number;
}


export {
    Response,
    IDreamItem,
    IExtendedDreamItem,
    IChangeDreamItem,
    ICreateDreamUserCurrency,
};

/* Params types */
interface IGetDreamsParams {
    active?: boolean;
    categories?: string[];
    is_vip?: boolean;
    page?: number;
    size?: number;
}

interface IGetFormattedDreamsParams {
    active?: boolean;
    categories?: string;
    is_vip?: boolean;
    page?: number;
    size?: number;
}

export {
    IGetDreamsParams,
    IGetFormattedDreamsParams,
};

/* Return types */

type TGetCreateDreamUserCurrencyApiData = Response<ICreateDreamUserCurrency>;


/* eslint-disable */
interface ICategory {
    id: number,
    title_cat: string,
}
/* eslint-enable */

// eslint-disable-next-line
enum FetchStatuses {
    default = 'default',
    pending = 'pending',
    failed = 'failed',
    success = 'success',
};

interface DreamsState {
    dreams: IDreamItem[];
    dreamItem: IDreamItem | null;
    dreamChangeItem: IChangeDreamItem | null;
    createDreamUserCurrency: ICreateDreamUserCurrency | null;
    categoryList: ICategory[];
    dreamsTotal: number;
    createDreamApiStatus: FetchStatuses;
    relations: IRelations[] | null;
    // eslint-disable-next-line camelcase
    hide_landing: boolean;
}

export interface IGetRelationsParams {
    dreamId: number;
    rate: number;
}

export {
    TGetCreateDreamUserCurrencyApiData,
    DreamsState,
    FetchStatuses,
};