import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
import Vue from 'vue';
import router from '../router';

if (process.env.VUE_APP_SENTRY_DSN && process.env.NODE_ENV === 'production') {
    Sentry.init({
        Vue,
        dsn: process.env.VUE_APP_SENTRY_DSN,
        integrations: [
            new BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                tracingOrigins: ['*'],
            }),
        ],
        tracesSampleRate: 1.0,
        release: process.env.VUE_APP_RELEASE,
    });
}