import { actions } from './actions';
import { mutations } from './mutations';
import { OperatorsState } from './types';

const state: OperatorsState = {
    loaderSetOperator: false,
    modalStateControl: false,
    createEmailError: '',
    operator: {
        email: '',
        isActive: false,
        isContentManager: false,
        isSuperuser: false,
        name: '',
        password: '',
    },
    operators: [],
    passwordServerError: '',
    operatorHandle: false,
    checkOperatorReset: false,
    preloader: true,
};

export default {
    namespaced: true,
    state: () => state,
    actions,
    mutations,
};