import { MutationTree } from 'vuex';
import { mutationsNames } from './names';
import { AdminState, IAdmin } from './types';

export const mutations: MutationTree<AdminState> = {
    [mutationsNames.UPDATE_ADMIN](state, admin: IAdmin) {
        state.admin = admin;
    },
    [mutationsNames.SET_TOKEN](state, token: string) {
        state.access = token;
    },
    [mutationsNames.UPDATE_ERROR](state, error: string) {
        state.error = error;
    },
};