import { ActionTree } from 'vuex';

import rest  from '@/api/index';

import { DonationState } from './types';
import { ComposedState } from '@/types/index';

import { actionsNames, mutationsNames } from './names';

export const actions: ActionTree<DonationState, ComposedState> = {
    async [actionsNames.getDonation]({ commit }, params) {
        if (params.donationId) {
            const resId = await rest.getUserDonations(params);
            commit(mutationsNames.SET_DONATION_USER, resId.data?.items);
            commit(mutationsNames.SET_TOTAL_DONATION, resId.data?.total);
            return;
        }

        const res = await rest.getDonations(params);

        if (res && res.status === 200 && res.data) {
            commit(mutationsNames.SET_DONATION, res.data.items);
            commit(mutationsNames.SET_TOTAL_DONATION, res.data.total);
        }
    },
};