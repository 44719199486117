import { ActionTree } from 'vuex';

import newsService from './services';

import { IPost, NewsState, News } from './types';

import { ComposedState, QueryParams } from '@/types/index';

import { actionsNames, mutationsNames } from './names';

export const actions: ActionTree<NewsState, ComposedState> = {
    async [actionsNames.fetchNews]({ commit }, params: QueryParams) {
        const response: any = await newsService.fetchNewsList(params);

        if (response && response.status === 200) {
            commit(mutationsNames.UPDATE_NEWS, {
                news: response.data?.items,
                count: response.data?.total,
            });

            return response.data?.total;
        }
    },

    async [actionsNames.createNews]({ commit }, params: FormData) {
        const res = await newsService.createNews(params);

        if (res) {
            commit(mutationsNames.SET_LANGUAGE_OF_LAST_CREATED_NEW, params.get('language'));
        }

        return res;
    },

    async [actionsNames.updateNews]({}, { params, id }: { params: IPost; id: number }) {
        return newsService.updateNews(params, id);
    },

    async [actionsNames.deleteNews]({}, { id }: { id: number }) {
        return newsService.deleteNews({ id });
    },
};