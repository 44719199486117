import { actions } from './actions';
import { mutations } from './mutations';
import { NewsState } from './types';

const state: NewsState = {
    news: [],
    count: null,
    languageOfLastCreatedNew: null,
};

export default {
    namespaced: true,
    state: () => state,
    actions,
    mutations,
};