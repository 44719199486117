import { addModuleName } from '@/tools/addModuleNames';

const MODULE_NAME = 'operators';

/* eslint-disable */
enum mutationsNames {
    UPDATE_SET_OPERATOR = 'UPDATE_SET_OPERATOR',
    UPDATE_MODAL_STATE_CONTROL = 'UPDATE_MODAL_STATE_CONTROL',
    UPDATE_CREATE_EMAIL_ERROR = 'UPDATE_CREATE_EMAIL_ERROR',
    UPDATE_OPERATOR = 'UPDATE_OPERATOR',
    UPDATE_PASSWORD_SERVER_ERROR = 'UPDATE_PASSWORD_SERVER_ERROR',
    UPDATE_OPERATOR_HANDLE = 'UPDATE_OPERATOR_HANDLE',
    UPDATE_CHECK_OPERATOR_RESET = 'UPDATE_CHECK_OPERATOR_RESET',
    UPDATE_OPERATORS = 'UPDATE_OPERATORS',
    UPDATE_PRELOADER = 'UPDATE_PRELOADER',
}

enum actionsNames {
    createOperator = 'createOperator',
    operatorResetPassword = 'operatorResetPassword',
    fetchOperator = 'fetchOperator',
    mutateOperator = 'mutateOperator',
    promoteOperator = 'promoteOperator',
    manageContentManagerRole = 'manageContentManagerRole',
    blockOperator = 'blockOperator',
    unBlockOperator = 'unBlockOperator',
    fetchOperators = 'fetchOperators',
}
/* eslint-enable */

const operatorsMutations = {
    [mutationsNames.UPDATE_SET_OPERATOR]: addModuleName(
        MODULE_NAME,
        mutationsNames.UPDATE_SET_OPERATOR,
    ),
    [mutationsNames.UPDATE_MODAL_STATE_CONTROL]: addModuleName(
        MODULE_NAME,
        mutationsNames.UPDATE_MODAL_STATE_CONTROL,
    ),
    [mutationsNames.UPDATE_CREATE_EMAIL_ERROR]: addModuleName(
        MODULE_NAME,
        mutationsNames.UPDATE_CREATE_EMAIL_ERROR,
    ),
    [mutationsNames.UPDATE_OPERATOR]: addModuleName(
        MODULE_NAME,
        mutationsNames.UPDATE_OPERATOR,
    ),
    [mutationsNames.UPDATE_PASSWORD_SERVER_ERROR]: addModuleName(
        MODULE_NAME,
        mutationsNames.UPDATE_PASSWORD_SERVER_ERROR,
    ),
    [mutationsNames.UPDATE_OPERATOR_HANDLE]: addModuleName(
        MODULE_NAME,
        mutationsNames.UPDATE_OPERATOR_HANDLE,
    ),
    [mutationsNames.UPDATE_CHECK_OPERATOR_RESET]: addModuleName(
        MODULE_NAME,
        mutationsNames.UPDATE_CHECK_OPERATOR_RESET,
    ),
    [mutationsNames.UPDATE_OPERATORS]: addModuleName(
        MODULE_NAME,
        mutationsNames.UPDATE_OPERATORS,
    ),
    [mutationsNames.UPDATE_PRELOADER]: addModuleName(
        MODULE_NAME,
        mutationsNames.UPDATE_PRELOADER,
    ),
};

const operatorsActions = {
    [actionsNames.createOperator]: addModuleName(
        MODULE_NAME,
        actionsNames.createOperator,
    ),
    [actionsNames.operatorResetPassword]: addModuleName(
        MODULE_NAME,
        actionsNames.operatorResetPassword,
    ),
    [actionsNames.fetchOperator]: addModuleName(
        MODULE_NAME,
        actionsNames.fetchOperator,
    ),
    [actionsNames.mutateOperator]: addModuleName(
        MODULE_NAME,
        actionsNames.mutateOperator,
    ),
    [actionsNames.promoteOperator]: addModuleName(
        MODULE_NAME,
        actionsNames.promoteOperator,
    ),
    [actionsNames.manageContentManagerRole]: addModuleName(
        MODULE_NAME,
        actionsNames.manageContentManagerRole,
    ),
    [actionsNames.blockOperator]: addModuleName(
        MODULE_NAME,
        actionsNames.blockOperator,
    ),
    [actionsNames.unBlockOperator]: addModuleName(
        MODULE_NAME,
        actionsNames.unBlockOperator,
    ),
    [actionsNames.fetchOperators]: addModuleName(
        MODULE_NAME,
        actionsNames.fetchOperators,
    ),
};

export {
    mutationsNames,
    operatorsMutations,
    actionsNames,
    operatorsActions,
};