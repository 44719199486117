const theme = {
    primary: '#03bafe',
    secondary: '#a5e0ff',
    tertiary: '#495057',
    accent: '#82b1ff',
    error: '#82b1ff',
    info: '#2196f3',
    success: '#48d2a0',
    warning: '#ffa200',
};

export default theme;
