import { actions } from './actions';
import { mutations } from './mutations';
import { GeneralState } from './types';

const state: GeneralState = {
    langs: [],
    countries: [],
    initialization: true,
    loader: false,
    drawer: false,
    upload: {
        process: false,
        loading: true,
        check: false,
    },
    errors: [],
    standartErrors: [],
    reviews: [],
    reviewsTotal: 0,
};

export default {
    namespaced: true,
    state: () => state,
    actions,
    mutations,
};