
import Vue from 'vue';

import { mapState, mapActions } from 'vuex';

import { adminActions } from '@/store/modules/admin/names';
import { ComposedState } from '@/types';

export default Vue.extend({
    name: 'Header',

    computed: {
        ...mapState<ComposedState>({
            admin: (state: ComposedState) => state.admin.admin,
        }),
    },

    methods: {
        ...mapActions({
            logout: adminActions.logout,
        }),

        async logOut() {
            const res = await this.logout();

            if (res) {
                this.$router.push('/login');
            }
        },
    },
});
