import { Login, Response } from '@/types/index';
import { instance } from '@/api/axios-instance';

interface Headers {
    header: string;
    value: string | null;
}

const adminServices = {
    async auth(params: Login): Promise<Response<any>> {
        return await instance.post('/admin/token', params).catch((e) => e.response);
    },

    async refresh(): Promise<Response<any>> {
        return await instance
            .post('/admin/token/refresh')
            .catch((e) => e.response);
    },

    async logout(): Promise<Response<any>> {
        return await instance.post('admin/logout').catch((e) => e.response);
    },

    async setHeaders(params: Headers) {
        params.value
            ? (instance.defaults.headers.common[params.header] = params.value)
            : delete instance.defaults.headers.common[params.header];
    },
};

export default adminServices;