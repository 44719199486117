
import Vue from 'vue';

import { mapState } from 'vuex';

import { ComposedState } from './types';

export default Vue.extend({
    name: 'App',

    data() {
        return {
            snackbar: false,
        };
    },

    computed: {
        ...mapState<ComposedState>({
            initialization: (state: ComposedState) => state.general.initialization,
            standartErrors: (state: ComposedState) => state.general.standartErrors,
        }),

        layout() {
            // @ts-ignore
            if (this.initialization) {
                return 'initialization';
            } else if (this.$route.name) {
                return `${this.$route.meta?.layout || 'main'}-layout`;
            } 
            return 'login-layout';
            
        },
    },

    watch: {
        standartErrors() {
            // @ts-ignore
            if (this.standartErrors.length) {
                this.snackbar = true;
            }
        },
    },
});
