import  { instance }  from '@/api/axios-instance';

import {
    Response,
    IAllCurrenciesApiData,
    IGetAllCurrenciesParams,
    ICurrencyData,
} from '@/store/modules/currency/types';

import { IDonation, IDonationParams } from '@/store/modules/donations/types';

import { ICountry, IQueryParamsReview, IReviewResponse } from '@/store/modules/general/types';
import { News } from '@/store/modules/news/types';
import { 
    IParams, 
    ISpecialUsers, 
    User, 
    UsersQueryType,
    UserBlock,
    UserUnblock,
    UserDelete, 
} from '@/store/modules/users/types';
import { 
    QueryParams, 
    IRelations, 
    ILikesConfig, 
    IViewsConfig, 
    ILang, 
    ILoadFile,
} from '@/types/index';

import {
    IGetDreamsParams,
    ICreateDreamUserCurrency,
    IDreamItem,
} from '../store/modules/dreams/types';

import {
    IResponseGetAllLanguages,
    IResponseSetVipStatus,
    IResponseUsers,
    IResponseWithItems,
    IResponseUserBlock,
    IResponseUserDelete,
} from './types';

export default {
    // eslint-disable-next-line 
    async setVipStatus(params: { user_id: number, is_vip: boolean }): Promise<Response<IResponseSetVipStatus>> {
        return instance
            .post('/admin/change-vip-status', params)
            .catch((e) => e.response);
    },

    async getSpecialUsers(params: IParams)
        : Promise<Response<IResponseWithItems<ISpecialUsers>>> {
        return instance
            .get('/admin/special-users', { params })
            .catch((e) => e.response);
    },

    async getUserInfo(id: number): Promise<Response<User>> {
        return instance
            .get(`/admin/user/${id}`)
            .catch((e) => e.response);
    },

    async blockUser(params: UserBlock): Promise<Response<IResponseUserBlock>> {
        return instance
            .post('/admin/users/block-account', params)
            .catch((e) => e.response);
    },

    async unblockUser(params: UserUnblock): Promise<Response<IResponseUserBlock>> {
        return instance
            .post('/admin/users/restored-account', params)
            .catch((e) => e.response);
    },

    async deleteUser(params: UserDelete): Promise<Response<IResponseUserDelete>> {
        return instance
            .post('/admin/users/delete-account', params)
            .catch((e) => e.response);
    },

    async getCountry(): Promise<Response<ICountry[]>> {
        return instance
            .get('/country')
            .catch((e) => e.response);
    },

    async getAllCurrencies(params: IGetAllCurrenciesParams)
        : Promise<Response<IAllCurrenciesApiData>> {
        return instance
            .get('/admin/currencies', { params })
            .catch((e) => e.response);
    },

    async updateCurrency(currency: ICurrencyData): Promise<Response<IAllCurrenciesApiData>> {
        return instance
            .patch(`/admin/currencies/${currency.id}`, currency)
            .catch((e) => e.response);
    },

    async createCurrency(currency: ICurrencyData): Promise<Response<IAllCurrenciesApiData>> {
        return instance
            .post('/admin/currencies', currency)
            .catch((e) => e.response);
    },

    async getDonations(params: IDonationParams)
        : Promise<Response<IResponseWithItems<IDonation[]>>> {
        if (params.statuses) {
            params.statuses = (params.statuses as []).join(',');
        }
        return instance
            .get('/admin/donation', { params })
            .catch((e) => e.response);
    },

    async getUserDonations(params: IDonationParams)
        : Promise<Response<IResponseWithItems<IDonation[]>>> {
        const id = params.donationId;
        if (params.statuses) {
            params.statuses = (params.statuses as []).join(',');
        }
        delete params.donationId;
        return instance
            .get(`/admin/donation/user/${id}`, { params })
            .catch((e) => e.response);
    },

    async getDreams(params: IGetDreamsParams): Promise<Response<IResponseWithItems<IDreamItem[]>>> {
        if (params.categories) {
            if (params.categories.length !== 0) {
                // @ts-ignore
                params.categories = params.categories.join(',');
            } else {
                delete params.categories;
            }
        }
        return instance
            .get('/admin/dream', { params })
            .catch((e) => e.response);
    },

    async getDreamItem(id: number): Promise<Response<IDreamItem>> {
        return instance
            .get(`/admin/dream/${id}`)
            .catch((e) => e.response);
    },

    async changeDreamItem(id: number, params: FormData): Promise<Response<void>> {
        return instance
            .patch(`/admin/dream/${id}`, params)
            .catch((e) => e.response);
    },

    async createDreamItem(payload: FormData): Promise<Response<void>> {
        return instance
            .post('/admin/dream', payload)
            .catch((e) => e.response);
    },

    async loadUserCurrency(userId: number): Promise<Response<ICreateDreamUserCurrency>> {
        return instance
            .get(`/user-currency/${userId}`)
            .catch((e) => e.response);
    },

    async fetchNewsList(params?: QueryParams): Promise<Response<IResponseWithItems<News[]>>> {
        return instance
            .get('/news', { params })
            .catch((e) => e.response);
    },

    async createNews(params: FormData): Promise<Response<void>> {
        return instance.post('/admin/news', params).catch((e) => e.response);
    },

    async updateNews(params: any, id: number): Promise<Response<void>> {
        return instance.patch(`/admin/news/${id}`, params).catch((e) => e.response);
    },

    async deleteNews({ id }: {id: number} ): Promise<Response<void>> {
        return instance.delete(`/admin/news/${id}`).catch((e) => e.response);
    },

    async exportDashboard(dateFrom: string, dateTo: string): Promise<Response<File>> {
        return instance.get(
            `${process.env.VUE_APP_URL_REST}`
                + `/admin/users/export?date_from=${dateFrom}&date_to=${dateTo}`,
            { responseType: 'blob' },
        ).catch((e) => e.response);
    },

    async changeSubscribe(id: number, newSubscribeDate: string, dreamLimit: number):
        Promise<Response<boolean>> {
        return instance.post(
            `admin/user/${id}/change-subscribe`, {
                subscribe_till: newSubscribeDate,
                dreams_to_activate: dreamLimit,
            },
        ).catch((e) => e.response);
    },

    async getAllLanguages(): Promise<Response<IResponseGetAllLanguages>> {
        return instance.get('/language/all').catch((e) => e.response);
    },

    async getReviews(params: IQueryParamsReview): Promise<Response<IReviewResponse>> {
        return instance.get('/admin/reviews', { params });
    },

    async createReview(review: FormData): Promise<Response<null>> {
        return instance.post('/admin/create-review', review);
    },

    async updateReview(id: number, review: FormData): Promise<Response<null>> {
        return instance.patch(`/admin/update-review/${id}`, review);
    },

    async getRelations(id: number, rate: number): Promise<Response<IRelations>> {
        return instance.get(`/admin/positions/${id}/${rate}`);
    },

    async deleteRelation(id: number): Promise<Response<string>> {
        return instance.delete(`/admin/positions/${id}`);
    },

    async changeRelation(id: number): Promise<Response<string>> {
        return instance.patch(`/admin/positions/${id}`);
    },

    async getLikesConfig(id: number): Promise<Response<ILikesConfig>> {
        return instance.get(`/admin/modified-likes/${id}`);
    },

    async sendLikesConfig(id: number, data: ILikesConfig): Promise<Response<string>> {
        return instance.patch(`/admin/modified-likes/${id}`, data);
    },

    async getViewsConfig(id: number): Promise<Response<IViewsConfig>> {
        return instance.get(`/admin/modified-views/${id}`);
    },

    async sendViewsConfig(id: number, data: IViewsConfig): Promise<Response<string>> {
        return instance.patch(`/admin/modified-views/${id}`, data);
    },

    async getUsers(params: UsersQueryType): Promise<Response<IResponseUsers>> {
        return instance.get('/admin/users', { params });
    },

    async getLanguages(): Promise<Response<ILang>> {
        return instance.get('/language/all');
    },

    async uploadVocabulary(formData: FormData): Promise<Response<ILoadFile>> {
        return instance.post('/admin/language/upload', formData);
    },
};