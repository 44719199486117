import { MutationTree } from 'vuex';
import { mutationsNames } from './names';
import { DonationState } from './types';

export const mutations: MutationTree<DonationState> = {
    [mutationsNames.SET_DONATION](state, payload) {
        state.donation = payload;
    },
    [mutationsNames.SET_DONATION_USER](state, payload) {
        state.donationUser = payload;
    },
    [mutationsNames.SET_TOTAL_DONATION](state, payload: number) {
        state.donationTotal = payload;
    },
};