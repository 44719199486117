import { addModuleName } from '@/tools/addModuleNames';

const MODULE_NAME = 'news';

/* eslint-disable */
enum mutationsNames {
    UPDATE_NEWS = 'UPDATE_NEWS',
    SET_LANGUAGE_OF_LAST_CREATED_NEW = 'SET_LANGUAGE_OF_LAST_CREATED_NEW'
}

enum actionsNames {
    fetchNews = 'fetchNews',
    createNews = 'createNews',
    updateNews = 'updateNews',
    deleteNews = 'deleteNews',
}
/* eslint-enable */

const newsMutations = {
    [mutationsNames.UPDATE_NEWS]: addModuleName(
        MODULE_NAME,
        mutationsNames.UPDATE_NEWS,
    ),
    [mutationsNames.SET_LANGUAGE_OF_LAST_CREATED_NEW]: addModuleName(
        MODULE_NAME,
        mutationsNames.SET_LANGUAGE_OF_LAST_CREATED_NEW,
    ),
};

const newsActions = {
    [actionsNames.fetchNews]: addModuleName(
        MODULE_NAME,
        actionsNames.fetchNews,
    ),
    [actionsNames.createNews]: addModuleName(
        MODULE_NAME,
        actionsNames.createNews,
    ),
    [actionsNames.updateNews]: addModuleName(
        MODULE_NAME,
        actionsNames.updateNews,
    ),
    [actionsNames.deleteNews]: addModuleName(
        MODULE_NAME,
        actionsNames.deleteNews,
    ),
};

export {
    mutationsNames,
    newsMutations,
    actionsNames,
    newsActions,
};