import Vue from 'vue';
import VueApollo from 'vue-apollo';
import router from '@/router/index';
import { onCatch } from './oncatch';

import {
    ApolloClient,
    ApolloLink,
    InMemoryCache,
    HttpLink,
} from 'apollo-boost';

import store from '@/store';
import { generalActions, generalMutations } from '@/store/modules/general/names';

const httpLink = new HttpLink({ uri: process.env.VUE_APP_URL });

const authMiddleware = new ApolloLink((operation, forward) => {
    const token = store.state.admin.access;
    
    operation.setContext({
        headers: {
            Authorization: token ? `JWT ${token}` : '',
        },
    });

    // Call the next link in the middleware chain.
    return forward(operation); 
});

const resetToken = onCatch(({ networkError, graphQLErrors }: any) => {
    store.commit(generalMutations.RESET_ERRORS);
    store.dispatch(generalActions.uploadReset, null, { root: true });

    if (networkError && networkError.statusCode === 401) {
        router.push('/login');
    } else {
        for (const i in graphQLErrors) {
            store.dispatch(generalActions.setErrors, graphQLErrors[i].message);
        }
    }
});

const authFlowLink = authMiddleware.concat(resetToken);
const link = authFlowLink.concat(httpLink);

export const apolloClient = new ApolloClient({
    link,
    cache: new InMemoryCache(),
    defaultOptions: {
        query: { fetchPolicy: 'no-cache' },
    },
});

Vue.use(VueApollo);

const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
});

export default apolloProvider;
