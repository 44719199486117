import { ActionTree } from 'vuex';

import rest  from '@/api/index';

import { DreamsState, FetchStatuses, IGetDreamsParams, IGetRelationsParams } from './types';
import { ComposedState } from '@/types/index';

import { actionsNames, mutationsNames } from './names';

export const actions: ActionTree<DreamsState, ComposedState> = {
    async [actionsNames.getDreams]({ commit }, params: IGetDreamsParams) {
        const res = await rest.getDreams(params);
        
        if (res && res.status === 200 && res.data) {
            commit(mutationsNames.SET_DREAMS, res.data.items);
            commit(mutationsNames.SET_TOTAL_DREAMS, res.data.total);
        }
    },

    async [actionsNames.getDreamItem]({ commit }, id: number) {
        const res = await rest.getDreamItem(id);
        
        if (res && res.status === 200 && res.data) {
            commit(mutationsNames.SET_DREAM_ITEM, res.data);
            commit(mutationsNames.SET_DREAM_CHANGE_ITEM, res.data);
        }
    },

    async [actionsNames.changeDreamItem]({ state, dispatch }) {
        const pushFormdata = new FormData();

        for (const item in state.dreamChangeItem) {
            pushFormdata.append(item, state.dreamChangeItem[item]);
        }
        const res = await rest.changeDreamItem(

            // @ts-ignore
            state.dreamItem.id,
            pushFormdata,
        );
        if (res && res.status === 201) {
            dispatch(actionsNames.getDreamItem, state.dreamItem?.id);
        }
    },

    async [actionsNames.createDreamItem]({ commit }, payload) {
        commit(mutationsNames.SET_CREATE_DREAM_STATUS, FetchStatuses.pending);

        const res = await rest.createDreamItem(payload);

        if (res && res.data) {
            commit(mutationsNames.SET_CREATE_DREAM_STATUS, FetchStatuses.failed);
        } else {
            commit(mutationsNames.SET_CREATE_DREAM_STATUS, FetchStatuses.success);
        }
    },

    async [actionsNames.loadUserCurrency]({ commit }, userId: number) {
        const res = await rest.loadUserCurrency(userId);

        if (res && res.status === 200 && res.data) {
            commit(mutationsNames.SET_USER_CURRENCY, res.data);
        }
    },

    async [actionsNames.getRelations]({ commit }, params: IGetRelationsParams) {
        try {
            const { dreamId, rate } = params;
            const res = await rest.getRelations(dreamId, rate);
            if (res && res.status === 200 && res.data) {
                commit(mutationsNames.SET_RELATIONS, res.data);
            } else {
                commit(mutationsNames.SET_RELATIONS, null);
            }
        } catch (e) {
            commit(mutationsNames.SET_RELATIONS, null);
        }
    },
};