import { addModuleName } from '@/tools/addModuleNames';

const MODULE_NAME = 'donations';

/* eslint-disable */
enum mutationsNames {
    SET_DONATION = 'SET_DONATION',
    SET_DONATION_USER = 'SET_DONATION_USER',
    SET_TOTAL_DONATION = 'SET_TOTAL_DONATION',
}

enum actionsNames {
    getDonation = 'getDonation',
}
/* eslint-enable */

const donationsMutations = {
    [mutationsNames.SET_DONATION]: addModuleName(
        MODULE_NAME,
        mutationsNames.SET_DONATION,
    ),
    [mutationsNames.SET_DONATION_USER]: addModuleName(
        MODULE_NAME,
        mutationsNames.SET_DONATION_USER,
    ),
    [mutationsNames.SET_TOTAL_DONATION]: addModuleName(
        MODULE_NAME,
        mutationsNames.SET_TOTAL_DONATION,
    ),
};

const donationsActions = {
    [actionsNames.getDonation]: addModuleName(
        MODULE_NAME,
        actionsNames.getDonation,
    ),
};

export {
    mutationsNames,
    donationsMutations,
    actionsNames,
    donationsActions,
};