import { actions } from './actions';
import { mutations } from './mutations';
import { CurrencyState, CurrencyStatuses } from './types';

const state: CurrencyState = {
    allCurrencies: [],
    editStatus: CurrencyStatuses.default,
};

export default {
    namespaced: true,
    state: () => state,
    actions,
    mutations,
};