import { addModuleName } from '@/tools/addModuleNames';

const MODULE_NAME = 'admin';

/* eslint-disable */
enum mutationsNames {
    UPDATE_ADMIN = 'UPDATE_ADMIN',
    SET_TOKEN = 'SET_TOKEN',
    UPDATE_ERROR = 'UPDATE_ERROR',
}

enum actionsNames {
    fetchAdmin = 'fetchAdmin',
    getToken = 'getToken',
    refresh = 'refresh',
    regularRefresh = 'regularRefresh',
    logout = 'logout',
}
/* eslint-enable */

const adminMutations = {
    [mutationsNames.UPDATE_ADMIN]: addModuleName(
        MODULE_NAME,
        mutationsNames.UPDATE_ADMIN,
    ),
    [mutationsNames.SET_TOKEN]: addModuleName(
        MODULE_NAME,
        mutationsNames.SET_TOKEN,
    ),
    [mutationsNames.UPDATE_ERROR]: addModuleName(
        MODULE_NAME,
        mutationsNames.UPDATE_ERROR,
    ),
};

const adminActions = {
    [actionsNames.fetchAdmin]: addModuleName(
        MODULE_NAME,
        actionsNames.fetchAdmin,
    ),
    [actionsNames.getToken]: addModuleName(
        MODULE_NAME,
        actionsNames.getToken,
    ),
    [actionsNames.refresh]: addModuleName(
        MODULE_NAME,
        actionsNames.refresh,
    ),
    [actionsNames.regularRefresh]: addModuleName(
        MODULE_NAME,
        actionsNames.regularRefresh,
    ),
    [actionsNames.logout]: addModuleName(
        MODULE_NAME,
        actionsNames.logout,
    ),
};

export {
    mutationsNames,
    adminMutations,
    actionsNames,
    adminActions,
};