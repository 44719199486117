
import Vue from 'vue';

export default Vue.extend({
    props: {
        showLoader: {
            type: Boolean,
            default: false,
        },
    },
});
