import { addModuleName } from '@/tools/addModuleNames';

const MODULE_NAME = 'users';

/* eslint-disable */
enum mutationsNames {
    UPDATE_USER = 'UPDATE_USER',
    UPDATE_USER_EMAIL = 'UPDATE_USER_EMAIL',
    UPDATE_PAYMENTS = 'UPDATE_PAYMENTS',
    UPDATE_LOADED_STATUS = 'UPDATE_LOADED_STATUS',
    SET_VIP_STATUS = 'SET_VIP_STATUS',
    SET_DONATIONS = 'SET_DONATIONS',
    UPDATE_DEFAULT_COUNTRY = 'UPDATE_DEFAULT_COUNTRY',
    UPDATE_USER_STATUS = 'UPDATE_USER_STATUS',
    DELETE_USER = 'DELETE_USER',
    UPDATE_USERS_COUNT = 'UPDATE_USERS_COUNT',
    UPDATE_SPECIAL_USERS = 'UPDATE_SPECIAL_USERS',
    SET_SPECIAL_USERS_TOTAL = 'SET_SPECIAL_USERS_TOTAL',
    UPDATE_USERS = 'UPDATE_USERS',
}

enum actionsNames {
    createUser = 'createUser',
    fetchPayments = 'fetchPayments',
    fetchUser = 'fetchUser',
    setVipStatus = 'setVipStatus',
    editUser = 'editUser',
    blockUser = 'blockUser',
    unblockUser = 'unblockUser',
    deleteUser = 'deleteUser',
    postProlongPay = 'postProlongPay',
    rollbackPayment = 'rollbackPayment',
    paymentDataOrderUpdate = 'paymentDataOrderUpdate',
    updateUserInfo = 'updateUserInfo',
    loginAsLink = 'loginAsLink',
    fetchUsers = 'fetchUsers',
    fetchSpecialUsers = 'fetchSpecialUsers',
    getDonations = 'getDonations',
    changeSubscribe = 'changeSubscribe',
}

enum gettersNames {
    commentRules = 'commentRules',
}
/* eslint-enable */

const usersMutations = {
    [mutationsNames.UPDATE_USER]: addModuleName(
        MODULE_NAME,
        mutationsNames.UPDATE_USER,
    ),
    [mutationsNames.UPDATE_USER_EMAIL]: addModuleName(
        MODULE_NAME,
        mutationsNames.UPDATE_USER_EMAIL,
    ),
    [mutationsNames.UPDATE_PAYMENTS]: addModuleName(
        MODULE_NAME,
        mutationsNames.UPDATE_PAYMENTS,
    ),
    [mutationsNames.UPDATE_LOADED_STATUS]: addModuleName(
        MODULE_NAME,
        mutationsNames.UPDATE_LOADED_STATUS,
    ),
    [mutationsNames.SET_VIP_STATUS]: addModuleName(
        MODULE_NAME,
        mutationsNames.SET_VIP_STATUS,
    ),
    [mutationsNames.SET_DONATIONS]: addModuleName(
        MODULE_NAME,
        mutationsNames.SET_DONATIONS,
    ),
    [mutationsNames.UPDATE_DEFAULT_COUNTRY]: addModuleName(
        MODULE_NAME,
        mutationsNames.UPDATE_DEFAULT_COUNTRY,
    ),
    [mutationsNames.UPDATE_USER_STATUS]: addModuleName(
        MODULE_NAME,
        mutationsNames.UPDATE_USER_STATUS,
    ),
    [mutationsNames.DELETE_USER]: addModuleName(
        MODULE_NAME,
        mutationsNames.DELETE_USER,
    ),
    [mutationsNames.UPDATE_USERS_COUNT]: addModuleName(
        MODULE_NAME,
        mutationsNames.UPDATE_USERS_COUNT,
    ),
    [mutationsNames.UPDATE_SPECIAL_USERS]: addModuleName(
        MODULE_NAME,
        mutationsNames.UPDATE_SPECIAL_USERS,
    ),
    [mutationsNames.SET_SPECIAL_USERS_TOTAL]: addModuleName(
        MODULE_NAME,
        mutationsNames.SET_SPECIAL_USERS_TOTAL,
    ),
    [mutationsNames.UPDATE_USERS]: addModuleName(
        MODULE_NAME,
        mutationsNames.UPDATE_USERS,
    ),
};

const usersActions = {
    [actionsNames.createUser]: addModuleName(
        MODULE_NAME,
        actionsNames.createUser,
    ),
    [actionsNames.fetchPayments]: addModuleName(
        MODULE_NAME,
        actionsNames.fetchPayments,
    ),
    [actionsNames.fetchUser]: addModuleName(
        MODULE_NAME,
        actionsNames.fetchUser,
    ),
    [actionsNames.setVipStatus]: addModuleName(
        MODULE_NAME,
        actionsNames.setVipStatus,
    ),
    [actionsNames.editUser]: addModuleName(
        MODULE_NAME,
        actionsNames.editUser,
    ),
    [actionsNames.blockUser]: addModuleName(
        MODULE_NAME,
        actionsNames.blockUser,
    ),
    [actionsNames.unblockUser]: addModuleName(
        MODULE_NAME,
        actionsNames.unblockUser,
    ),
    [actionsNames.deleteUser]: addModuleName(
        MODULE_NAME,
        actionsNames.deleteUser,
    ),
    [actionsNames.postProlongPay]: addModuleName(
        MODULE_NAME,
        actionsNames.postProlongPay,
    ),
    [actionsNames.rollbackPayment]: addModuleName(
        MODULE_NAME,
        actionsNames.rollbackPayment,
    ),
    [actionsNames.paymentDataOrderUpdate]: addModuleName(
        MODULE_NAME,
        actionsNames.paymentDataOrderUpdate,
    ),
    [actionsNames.updateUserInfo]: addModuleName(
        MODULE_NAME,
        actionsNames.updateUserInfo,
    ),
    [actionsNames.loginAsLink]: addModuleName(
        MODULE_NAME,
        actionsNames.loginAsLink,
    ),
    [actionsNames.fetchUsers]: addModuleName(
        MODULE_NAME,
        actionsNames.fetchUsers,
    ),
    [actionsNames.fetchSpecialUsers]: addModuleName(
        MODULE_NAME,
        actionsNames.fetchSpecialUsers,
    ),
    [actionsNames.getDonations]: addModuleName(
        MODULE_NAME,
        actionsNames.getDonations,
    ),
    [actionsNames.changeSubscribe]: addModuleName(
        MODULE_NAME,
        actionsNames.changeSubscribe,
    ),
};

const usersGetters = {
    [gettersNames.commentRules]: addModuleName(
        MODULE_NAME,
        gettersNames.commentRules,
    ),
};

export {
    mutationsNames,
    usersMutations,
    actionsNames,
    usersActions,
    gettersNames,
    usersGetters,
};