import {
    QueryProlongPay,
    PaymentData,
    UserPutData,
    QueryLoginAsLink,
    EditUser,
    QueryDonations,
} from './types';

import graphqlQueries from '@/api/index.graphql';

const usersServices = {
    async userEmailChange(params: EditUser) {
        return graphqlQueries.userEmailChange(params);
    },
    
    async postProlongPay(params: QueryProlongPay) {
        return graphqlQueries.postProlongPay(params);
    },
    
    async rollbackPayment(donationId: number) {
        const params = { donationId };
    
        return graphqlQueries.rollbackPayment(params);
    },
    
    async paymentDataOrderUpdate(params: PaymentData) {
        return graphqlQueries.paymentDataOrderUpdate(params);
    },
    
    async userEdit(params: UserPutData) {    
        return graphqlQueries.userEdit(params);
    },
    
    async loginAsLink(params: QueryLoginAsLink) {  
        return graphqlQueries.loginAsLink(params);
    },

    async getDonations(params: QueryDonations) {
        return graphqlQueries.getDonations(params);
    },
};

export default usersServices;