
import Vue from 'vue';

import { mapActions, mapState } from 'vuex';

import {
    required,
    minLength,
    email,
} from 'vuelidate/lib/validators';

import { adminActions } from '@/store/modules/admin/names';
import { ComposedState } from '@/types';

export default Vue.extend({
    name: 'Login',

    validations: {
        profile: {
            username: { required, email },
            password: { required, minLength: minLength(8) },
        },
    },

    data() {
        return {
            process: false,

            profile: {
                username: '',
                password: '',
            },

            errors: [] as string[],
        };
    },

    computed: {
        ...mapState<ComposedState>({
            token: (state: ComposedState) => state.admin.access,
            upload: (state: ComposedState) => state.general.upload,
        }),

        emailErrors() {
            const errors: string[] = [];
            if (!this.$v.profile.username?.$dirty) return errors;
            if (!this.$v.profile.username.email) {
                errors.push('Email must be valid.');
            }
            if (!this.$v.profile.username.required) {
                errors.push('Email is required.');
            }
            return errors;
        },

        passwordErrors() {
            const errors: string[] = [];
            if (!this.$v.profile.password?.$dirty) return errors;
            if (!this.$v.profile.password.minLength) {
                errors.push('Min 8 characters.');
            }
            if (!this.$v.profile.password.required) {
                errors.push('Password is required.');
            }
            return errors;
        },
    },

    methods: {
        ...mapActions({
            getToken: adminActions.getToken,
        }),

        async login() {
            this.$v.$touch();
            this.errors = [];

            if (!this.$v.$invalid) {
                this.process = true;

                const res = await this.getToken(this.profile);

                if (res) {
                    this.$v.$reset();
                    this.$router.replace({ name: 'home' });
                } else {
                    this.errors.push('Email or password entered incorrectly');
                }
            }
        },
    },
});
