import { GetterTree } from 'vuex';

import { UsersState } from './types';
import { ComposedState } from '@/types/index';

import { gettersNames } from './names';

export const getters: GetterTree<UsersState, ComposedState> = {
    [gettersNames.commentRules]: () => [
        (v: any) => {
            if (!v || (v.length > 3 && v.length < 301)) {
                return true;
            }

            return 'Specify the reason for blocking/deleting the user';
        },
    ],
};