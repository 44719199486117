import { ActionTree } from 'vuex';

import { DashboardState } from './types';
import { ComposedState } from '@/types/index';

import dashboardServises from './services';

import { actionsNames, mutationsNames } from './names';

export const actions: ActionTree<DashboardState, ComposedState> = {
    async [actionsNames.getDashboard]({ commit }) {
        const res = await dashboardServises.getDashboard();

        if (!res || !res.data) {
            return;
        }
        
        const { dashboard } = res.data;

        commit(mutationsNames.UPDATE_ACTIVE_USERS, dashboard.activeUsers);
        commit(mutationsNames.UPDATE_COUNTRY_STATISTIC, dashboard.countryStatistic);

        return dashboard;
    },
};